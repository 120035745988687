import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserIncome() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    dispatch(updateProperty({ prop: "income_annual", value }));
    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro messages={[<>What is your annual income?</>]} />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"column"} sx={{ mb: 2 }}>
            <Button
              variant={
                quote.item.income_annual === 0 ? "contained" : "outlined"
              }
              startIcon={quote.item.income_annual === 0 ? <CheckIcon /> : null}
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(0)}
              fullWidth
            >
              Less than 1 Million Naira (NGN)
            </Button>
            <Button
              variant={
                quote.item.income_annual === 1 ? "contained" : "outlined"
              }
              startIcon={quote.item.income_annual === 1 ? <CheckIcon /> : null}
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(1)}
              fullWidth
            >
              Between 1 and 2 Million Naira (NGN)
            </Button>
            <Button
              variant={
                quote.item.income_annual === 2 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.user_income_nnual === 2 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(2)}
              fullWidth
            >
              Between 2 and 5 Million Naira (NGN)
            </Button>
            <Button
              variant={
                quote.item.income_annual === 5 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.user_income_nnual === 5 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(5)}
              fullWidth
            >
              Between 5 and 10 Million Naira (NGN)
            </Button>
            <Button
              variant={
                quote.item.income_annual === 10 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.user_income_nnual === 10 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(10)}
              fullWidth
            >
              Between 10 and 15 Million Naira (NGN)
            </Button>
            <Button
              variant={
                quote.item.income_annual === 15 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.user_income_nnual === 15 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(15)}
              fullWidth
            >
              More than 15 Million Naira (NGN)
            </Button>
          </Stack>
        </form>
      </DialogForm>
      {/* <Box sx={{ mt: 6 }}>
        <Stack gap={2}>
          <Button onClick={() => dispatch(setDialogOpen(true))} color="inherit">
            I need Help
          </Button>
        </Stack>
      </Box> */}
    </>
  );
}
