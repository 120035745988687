// Currency
export const formatCurrency = (value, currency = "ZAR", intl = "en-ZA") => {
  let currencyString = value;
  try {
    currencyString = new Intl.NumberFormat(intl, {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    }).format(value);
  } catch (e) {}

  return currencyString;
};
