import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// Components
import AgentDialog from "./AgentDialog";

//******************************************************************************
export default function DialogIntro({ messages, isLoading = false }) {
  let timer;

  const messageTyping = (item) => {
    const elLoad = document.getElementById(`item-load-${item}`);
    const elMessage = document.getElementById(`item-message-${item}`);
    if (elLoad && elMessage) {
      elLoad.classList.add("hidden-none");
      elMessage.classList.remove("hidden-none");

      if (item < messages.length - 1)
        timer = setTimeout(() => loadMessage(item + 1), 1000);
    }
  };

  const loadMessage = (item) => {
    const el = document.getElementById(`item-${item}`);
    if (el) {
      el.classList.remove("hidden");
      el.classList.add("messageInAnimation");

      timer = setTimeout(() => messageTyping(item), 2000);
    }

    // if (item < messages.length - 1)
    //   timer = setTimeout(() => messageTyping(item + 1), 1000);
  };

  const displayMessage = (item) => {
    const el = document.getElementById(`item-${item}`);
    if (el) {
      el.classList.remove("hidden");
      el.classList.add("messageInAnimation");
      const elLoad = document.getElementById(`item-load-${item}`);
      const elMessage = document.getElementById(`item-message-${item}`);
      elLoad.classList.add("hidden-none");
      elMessage.classList.remove("hidden-none");
    }
  };

  const animateDialog = () => {
    if (messages) {
      if (messages.length > 2) {
        displayMessage(0);
        displayMessage(1);
        timer = setTimeout(() => loadMessage(2), 1000);
      } else if (messages.length === 2) {
        displayMessage(0);
        timer = setTimeout(() => loadMessage(1), 1000);
      } else if (messages.length === 1) {
        displayMessage(0);
      } else if (messages.length === 0) {
      } else timer = setTimeout(() => loadMessage(0), 1000);
    }
  };

  useEffect(() => {
    animateDialog();

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Stack gap={1}>
      {messages?.map((message, index) => (
        <Box id={`item-${index}`} className={"hidden"} key={index}>
          <AgentDialog>
            <div
              id={`item-load-${index}`}
              className="message-loading"
              style={{ margin: "0 20px" }}
            ></div>
            <div id={`item-message-${index}`} className="message hidden-none">
              <span className="message">{message}</span>
            </div>
          </AgentDialog>
        </Box>
      ))}
    </Stack>
  );

  return (
    <Stack gap={1}>
      {messages?.map((message, index) => (
        <Box
          className={isLoading && "messageInAnimation"}
          key={index}
          sx={{ animationDelay: index + 0.2 * Math.random() + "s" }}
        >
          <AgentDialog id={`item-${index}`}>
            <div class="message-loading" style={{ margin: "0 20px" }}></div>
            <div className="message">
              <span className="message">{message}</span>
            </div>
          </AgentDialog>
        </Box>
      ))}
    </Stack>
  );
}
