import { format, formatDistance, intervalToDuration } from "date-fns";

//import { clCartProduct } from "../app/objects/clProduct";

const validateDate = (value) => {
  try {
    let tmpDate = new Date(format(new Date(value), "yyyy-MM-dd"));
  } catch (e) {
    return false;
  }
  return true;
};

export const parseData = (reference, typeDef, data) => {
  let output = reference;
  for (const key in output) {
    if (output.hasOwnProperty(key) && data.hasOwnProperty(key)) {
      if (typeDef.hasOwnProperty(key)) {
        switch (typeDef[key]) {
          case "int":
          case "integer":
            if (Number.isInteger(parseInt(data[key])))
              output[key] = parseInt(data[key]);
            break;
          case "bool":
            if (data[key] === true || data[key] === "true") output[key] = true;
            else if (parseInt(data[key]) > 0) output[key] = true;
            else output[key] = false;
            break;
          case "date":
            if (validateDate(data[key])) output[key] = data[key];
            break;
          case "array(role)":
            // Parse array of roles
            let roles = [];
            if (Array.isArray(data[key])) {
              data[key].forEach((role) => {
                if (role.role_id && role.role) {
                  let newrole = {
                    id: role.role_id,
                    name: role.role,
                  };
                  roles.push(newrole);
                }
              });
            }
            output[key] = roles;
            break;
          case "array(payment)":
            // Parse array of payments
            let payments = [];
            if (Array.isArray(data[key])) {
              data[key].forEach((payment) => {
                if (
                  payment.id &&
                  payment.terms &&
                  payment.term &&
                  payment.amount
                ) {
                  let newPayment = {
                    id: payment.id,
                    terms: payment.terms,
                    term: payment.term,
                    amount: payment.amount,
                  };
                  payments.push(newPayment);
                }
              });
            }
            output[key] = payments;
            break;
          //   case "array(product)":
          //     // Parse array of products
          //     let products = [];
          //     if (Array.isArray(data[key])) {
          //       data[key].forEach((product) => {
          //         let newProduct = clCartProduct(product);
          //         products.push(newProduct);
          //       });
          //     }
          //     output[key] = products;
          //     break;
          case "json":
            try {
              if (data[key]) output[key] = JSON.parse(data[key]);
              else output[key] = [];
            } catch (e) {
              output[key] = [];
            }
            break;
          default:
            output[key] = data[key];
        }
      } else output[key] = data[key];
    }
  }

  return output;
};

export const parseQueryString = (reference, data) => {
  let output = {};
  Object.assign(output, reference);
  for (const key in output) {
    if (output.hasOwnProperty(key)) {
      output[key] = data.get(key);
    }
  }

  return output;
};
