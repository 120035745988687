import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCarInsuranceCancel() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    dispatch(updateProperty({ prop: "car_insurance_cancel", value: value }));

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[<>Has your insurance ever been cancelled by an insurer?</>]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"row"} sx={{ mb: 2 }}>
            <Button
              variant={
                quote.item.car_insurance_cancel === "no"
                  ? "contained"
                  : "outlined"
              }
              startIcon={
                quote.item.car_insurance_cancel === "no" ? <CheckIcon /> : null
              }
              onClick={() => handleNextStep("no")}
              fullWidth
              disabled={isBusy}
            >
              No
            </Button>
            <Button
              variant={
                quote.item.car_insurance_cancel === "yes"
                  ? "contained"
                  : "outlined"
              }
              startIcon={
                quote.item.car_insurance_cancel === "yes" ? <CheckIcon /> : null
              }
              onClick={() => handleNextStep("yes")}
              fullWidth
              disabled={isBusy}
            >
              Yes
            </Button>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
