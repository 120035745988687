import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { NavLink, useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

// Icons
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import congratulations from "../assets/congratulations.svg";

// Components
import Layout from "../components/layout/LayoutOrder";
import AlertBar from "../components/AlertBar";

// State
import { products } from "../app/lists/products";
import { STATUS_LOADING } from "../app/status";
import {
  verifyOrder,
  resetStatus,
  setDialogOpen,
} from "../app/features/quoteSlice";

//******************************************************************************
export default function OrderConfirmation() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [searchParams] = useSearchParams();
  const [isBusy, setIsBusy] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  const reference = searchParams.get("reference");

  const cur_product = products
    .filter((item) => item.product_type === quote.item.product_type)
    .map((item) => item);

  const verifyOrderReference = () => {
    if (reference === quote.item.uuid) {
      dispatch(verifyOrder())
        .then(unwrapResult)
        .then((originalPromiseResult) => {})
        .catch((rejectedValueOrSerializedError) => {})
        .finally(() => setIsBusy(false));
    } else {
      setErrMsg("Invalid reference");
      setIsBusy(false);
    }
  };

  useEffect(() => {
    if (reference && quote.item.uuid) {
      if (reference === quote.item.uuid) verifyOrderReference();
      else {
        setErrMsg("Invalid reference");
        setIsBusy(false);
      }
    } else {
      setIsBusy(false);
    }

    return () => {
      setErrMsg();
      dispatch(resetStatus());
      setIsBusy(true);
    };
  }, [reference, quote.item.uuid]);

  useEffect(() => {
    dispatch(setDialogOpen(false));
  }, []);

  // Busy loading
  if (isBusy || quote.status === STATUS_LOADING) {
    return (
      <Layout title="Verifying..." isLoading={true}>
        <Box sx={{ display: "flex", p: 2 }}>
          <CircularProgress />
        </Box>
      </Layout>
    );
  } else if (!reference) {
    // Nothing to do
    return (
      <Layout title="Nothing to Verify">
        <Button
          variant="contained"
          component={NavLink}
          to={"/"}
          startIcon={<HomeIcon />}
          sx={{ my: 2 }}
        >
          Go Home
        </Button>
      </Layout>
    );
  } else if (quote.error || errMsg) {
    // Errors
    return (
      <Layout title="Oops! Something went wrong.">
        <AlertBar variant={"outlined"} error={errMsg} />
        <AlertBar
          error={quote.error}
          onClose={() => dispatch(resetStatus())}
          sx={{ my: 2 }}
        />
        <Button variant="contained" onClick={() => verifyOrderReference()}>
          Retry
        </Button>
      </Layout>
    );
  } else if (quote.item.paid_status) {
    // Validate if we have all the required data
    return (
      <Layout title={"ORDER CONFIRMATION"}>
        <Box
          sx={{
            backgroundColor: "secondary.main",
            color: "white",
            width: "100%",
            textAlign: "center",
            padding: "20px 30px",
          }}
        >
          <Box sx={{ p: 2 }}>
            <img
              src={congratulations}
              alt="insurance"
              style={{ maxHeight: "55px", height: "100%" }}
            />
          </Box>
          <Typography sx={{ fontSize: "1.2rem", pb: 2 }} gutterBottom>
            Congratulations, {quote.item.first_name}!
          </Typography>
          <Typography sx={{ fontSize: "1rem", pb: 2 }} gutterBottom>
            Your{" "}
            <b>
              {quote.item.car_make} {quote.item.car_model}
            </b>{" "}
            is now insured with <b>{process.env.REACT_APP_NAME}</b>{" "}
            <b>
              {products
                .filter((item) => item.product_type === quote.item.product_type)
                .map((item) => item.name)}
            </b>
            .
          </Typography>

          {quote.item.reference_id ? (
            <>
              <Typography
                sx={{ fontSize: "1rem", textTransform: "uppercase", mt: 2 }}
              >
                Policy Number
              </Typography>
              <Box
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  mb: 2,
                }}
              >
                {quote.item.reference_id}
              </Box>
            </>
          ) : (
            <>
              <Typography
                sx={{ fontSize: "1rem", textTransform: "uppercase", mt: 2 }}
              >
                Your Reference
              </Typography>
              <Box
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "600",
                  mb: 2,
                }}
              >
                {quote.item.uuid}
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ textAlign: "center", pt: 4 }}>
          <Typography
            sx={{
              mt: 1,
              mb: 1,
              textTransform: "uppercase",
              fontWeight: 600,
              paddingInline: "30px",
            }}
          >
            THANK YOU FOR CHOOSING US AS YOUR INSURANCE PARTNER!
          </Typography>

          {quote.item.cover_note && (
            <Box sx={{ m: 4 }}>
              <Button
                variant="contained"
                href={quote.item.cover_note}
                target="_blank"
              >
                Download Cover Note
              </Button>
            </Box>
          )}

          {}
          {/* <Typography
            sx={{
              mt: 2,
              mb: 2,
              textWrap: "balance",
              fontWeight: 600,
            }}
          >
            An email containing your policy certificate will be sent to you
            shortly.
          </Typography> */}
          <Typography sx={{ borderBottom: "3px solid #eee", pb: 2 }}>
            Warm regards, <br />
            Allianz Nigeria
          </Typography>
          {/* 
          {quote.item.product_type === "car" && (
            <Typography sx={{ fontStyle: "italic", p: 3, textWrap: "balance" }}>
              Important Note: To ensure full activation of your new
              comprehensive motor insurance coverage, an in-person inspection is
              required within 30 days of policy issuance to avoid cancellation.
              An agent may contact you to schedule your inspection, or you can
              proactively reach out to an Allianz agent using your policy
              number.
            </Typography>
          )}
           */}
        </Box>
      </Layout>
    );
  } else {
    return (
      <Layout title={"Something went Wrong"}>
        <Box sx={{ textAlign: "center" }}>
          <AlertBar error={"Your payment was not successful."} />
          <Typography gutterBottom></Typography>

          <Button variant="contained" onClick={() => verifyOrderReference()}>
            Retry
          </Button>
        </Box>
      </Layout>
    );
  }
}
