import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Checkbox from "@mui/material/Checkbox";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import {
  updateProperty,
  nextStep,
  setDialogOpen,
} from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCarComplete() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Congratulations, <b>{quote.item.first_name}</b>! You've made it this
            far.
          </>,
          <>
            To validate your application, we need some additional information.
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"column"} sx={{ mb: 2 }}>
            <Stack
              direction="row"
              sx={{ alignItems: "center", justifyContent: "flex-start" }}
            >
              <Checkbox
                checked={quote.item.accept_terms}
                onChange={(e) => {
                  dispatch(
                    updateProperty({
                      prop: "accept_terms",
                      value: e.target.checked,
                    })
                  );
                  setErrors({ ...errors, terms: "" });
                }}
                sx={{ alignSelf: "flex-start", p: 0 }}
              />
              <Box sx={{ fontSize: "0.9rem", ml: 1 }}>
                I understand and accept the{" "}
                <Link
                  component={NavLink}
                  to={`/terms`}
                  onClick={() => dispatch(setDialogOpen(false))}
                >
                  Terms &amp; Conditions
                </Link>{" "}
                and{" "}
                <Link
                  component={NavLink}
                  to={`/privacy`}
                  onClick={() => dispatch(setDialogOpen(false))}
                >
                  Privacy Policy
                </Link>
                .
              </Box>
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant={"contained"}
                sx={{ minWidth: "150px" }}
                onClick={() => handleNextStep()}
                disabled={!quote.item.accept_terms}
              >
                Continue
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
