import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserComplete() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {};

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Great job, <b>{quote.item.first_name}</b>!
          </>,
          <>
            We have gathered all the necessary information to perform the
            verification checks.
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"column"} sx={{ mb: 2 }}>
            <Stack
              direction="row"
              sx={{ alignItems: "center", justifyContent: "flex-start" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quote.item.accept_form}
                    onChange={(e) => {
                      dispatch(
                        updateProperty({
                          prop: "accept_form",
                          value: e.target.checked,
                        })
                      );
                    }}
                    sx={{ p: 0, pl: 1 }}
                  />
                }
                label={
                  <Box sx={{ fontSize: "0.8rem", pl: 1 }}>
                    I declare to the best of my knowledge that the information
                    given on this form is true to every respect and I consent to
                    necessary verification checks. I agree that this proposal
                    and declaration shall be the basis of the contract between
                    me and the insurer ALLIANZ NIGERIA INSURANCE LIMITED.
                  </Box>
                }
                sx={{ alignItems: "flex-start" }}
              />
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant={"contained"}
                sx={{ minWidth: "150px" }}
                onClick={() => handleNextStep(0)}
                disabled={!quote.item.accept_form}
              >
                Continue
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
      {/* <Box sx={{ mt: 6 }}>
        <Stack gap={2}>
          <Button onClick={() => dispatch(setDialogOpen(true))} color="inherit">
            I need Help
          </Button>
        </Stack>
      </Box> */}
    </>
  );
}
