import axios from "axios";

var baseURL = "";

if (process.env.NODE_ENV === "development") {
  baseURL = process.env.REACT_APP_DOMAIN + process.env.REACT_APP_API_ROOT;
} else {
  baseURL = process.env.REACT_APP_DOMAIN + process.env.REACT_APP_API_ROOT;
}

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

const controller = new AbortController();
let pendingRequests = new Map();

// Request
instance.interceptors.request.use(
  async (config) => {
    let token;
    token = await localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

    if (!token) {
      token = await sessionStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);
    }

    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }

    // const requestKey = `${config.url}/${JSON.stringify(
    //   config.params
    // )}/${JSON.stringify(config.data)}&request_type=${config.method}`;

    // if (pendingRequests.has(requestKey)) {
    //   console.log("Abort:" + requestKey);
    //   //controller.abort();
    // } else {
    //   console.log(requestKey);
    //   pendingRequests.set(requestKey, config);
    //   config.requestKey = requestKey;
    // }

    const cfg = {
      ...config,
      signal: controller.signal,
    };

    return cfg;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// Response
instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const originalRequest = error.config;

    // Unable to refresh token (not allowed)
    if (
      error.response?.status === 401 &&
      originalRequest.url === "/auth/refresh"
    ) {
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      sessionStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      return Promise.reject(error);
    }

    // Previous request denied -> Refresh token and retry
    // - Only if previous request had token
    if (
      error.response?.status === 417 &&
      error.config.headers.getAuthorization() &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return instance.post("/auth/refresh").then((res) => {
        if (res.status === 200) {
          if (
            localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN) === null
          ) {
            sessionStorage.setItem(
              process.env.REACT_APP_ACCESS_TOKEN,
              res.data.token
            );
          } else {
            localStorage.setItem(
              process.env.REACT_APP_ACCESS_TOKEN,
              res.data.token
            );
          }

          instance.defaults.headers.common["Authorization"] =
            "Token " + res.data.token;
          return instance(originalRequest);
        }
      });
    }

    return Promise.reject(error);
  }
);

export default instance;
