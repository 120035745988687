import React, { useState } from "react";
import Box from "@mui/material/Box";

//******************************************************************************
export default function AgentDialog({ children }) {
  const textBoxStyle = {
    backgroundColor: "#b8d1e9",
    color: "#143861",
    p: 2,
    borderRadius: "0 20px 20px 20px",
    display: "inline-block",
  };

  return <Box sx={textBoxStyle}>{children} </Box>;
}
