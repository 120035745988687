import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

// Components
import MainButtonHome from "./ButtonHome";
import ButtonQuote from "./ButtonQuote";

// State
import { setDialogOpen } from "../../app/features/quoteSlice";

//******************************************************************************
export default function AppBar({ baseURL, buttonHome, isLoading }) {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          displayPrint: "none",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar className="main-menu" disableGutters>
          <Box
            sx={{
              flexGrow: [1, 1, 0],
              textAlign: ["center", "center", "left"],
              px: [0, 2],
            }}
          >
            {buttonHome ? (
              buttonHome
            ) : (
              <MainButtonHome
                baseURL={baseURL}
                disabled={isLoading ? true : false}
              />
            )}
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={2}
          >
            {/* <SwitchTheme /> */}
            <ButtonQuote />

            {/* <ButtonMyAccount /> */}
          </Stack>
        </Toolbar>
      </MuiAppBar>
    </>
  );
}
