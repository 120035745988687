import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import { products } from "../../app/lists/products";
import {
  validateCharacterCount,
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepName() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      first_name: "",
      last_name: "",
    };

    // Get the fields
    if (quote.item.first_name === "")
      tmpErr.first_name = "Please enter your name.";
    else if (!validateCharacterCount(quote.item.first_name, 2))
      tmpErr.first_name = "Invalid name.";
    if (quote.item.last_name === "")
      tmpErr.last_name = "Please enter your surname.";
    else if (!validateCharacterCount(quote.item.last_name, 2))
      tmpErr.last_name = "Invalid surname.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  const showForm = () => {
    const el = document.getElementById(`user-form`);
    el.classList.remove("hidden");
  };

  useEffect(() => {
    const timer = setTimeout(showForm, 6500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <DialogIntro
        isLoading={
          !quote.item.first_name &&
          !quote.item.last_name &&
          !quote.item.car_make
        }
        messages={[
          <>
            Hello, I'm <b>{process.env.REACT_APP_AGENT_NAME}</b> from{" "}
            <b>{process.env.REACT_APP_NAME}</b>. Let me help you sign up. It’s
            quick and easy.
          </>,
          <>Make sure you've got your personal and vehicle details close by.</>,
          <>Ready to start?</>,
          <>What’s your name and surname?</>,
        ]}
      />

      <Box id="user-form" className={"hidden"}>
        <DialogForm
        // messages={[
        //   ((quote.item.product_type === "car" && quote.item.car_make) ||
        //     (quote.item.product_type === "third" && quote.item.address1)) && (
        //     <>
        //       I am <b>{quote.item.first_name + " " + quote.item.last_name}</b>{" "}
        //       and I want the best quote for{" "}
        //       {quote.item.product_type === "car"
        //         ? `Car Insurance for my ${quote.item.car_make}`
        //         : "Third Party Insurance"}
        //     </>
        //   ),
        // ]}
        >
          <form id={"editForm"}>
            <Stack gap={2}>
              <Stack gap={2} direction={["column", "row"]}>
                <TextField
                  id="first_name"
                  label="Name"
                  variant="outlined"
                  value={quote.item.first_name}
                  fullWidth
                  onChange={(e) =>
                    dispatch(
                      updateProperty({
                        prop: "first_name",
                        value: e.target.value,
                      })
                    )
                  }
                  error={errors.first_name !== ""}
                  helperText={errors.first_name}
                  required
                  disabled={isBusy}
                  inputProps={{ maxLength: 20 }}
                />
                <TextField
                  id="last_name"
                  label="Surname"
                  variant="outlined"
                  value={quote.item.last_name}
                  fullWidth
                  onChange={(e) =>
                    dispatch(
                      updateProperty({
                        prop: "last_name",
                        value: e.target.value,
                      })
                    )
                  }
                  error={errors.last_name !== ""}
                  helperText={errors.last_name}
                  required
                  disabled={isBusy}
                  inputProps={{ maxLength: 20 }}
                />
              </Stack>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  sx={{ minWidth: "150px" }}
                  disabled={
                    !quote.item.first_name ||
                    !quote.item.last_name ||
                    isBusy ||
                    quote.status === STATUS_LOADING
                  }
                  onClick={handleNextStep}
                >
                  Next
                </Button>
              </Box>
            </Stack>
          </form>
        </DialogForm>
      </Box>
    </>
  );
}
