import React from "react";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

// Icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//******************************************************************************
export default function ProductItem({ product, onClick, disabled }) {
  return (
    <Card
      elevation={3}
      sx={{
        borderRadius: "30px",
        backgroundColor: "white",
      }}
    >
      <CardActionArea
        onClick={onClick}
        sx={{
          fontSize: "1.2rem",
          p: [2, 4],
          pl: [3, 4, 6],
        }}
        disabled={disabled ? true : false}
      >
        <Stack
          direction={"row"}
          sx={{ justifyContent: "center", alignItems: "center" }}
          gap={2}
        >
          <Box sx={{ minWidth: "60px" }}>
            {product?.image && (
              <img
                src={product.image}
                style={{ width: "100%", maxWidth: "60px", marginTop: "5px" }}
                alt={product?.name}
              />
            )}
          </Box>
          <Box
            sx={{
              color: disabled ? "#aaa" : "secondary.main",
              textAlign: "left",
              flexGrow: 1,
              fontSize: ["1rem", "1.3rem"],
              fontWeight: 500,
            }}
          >
            {product?.name}
          </Box>
          <Box
            sx={{
              backgroundColor: disabled ? "divider" : "primary.main",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "36px",
              minWidth: "36px",
              height: "36px",
              borderRadius: "20px",
            }}
          >
            <ChevronRightIcon sx={{ color: "white" }} />
          </Box>
        </Stack>
      </CardActionArea>
    </Card>
  );
}
