import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";

// Components
import Agent from "./Agent";
import AgentDialog from "./AgentDialog";
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import ButtonOption from "./ButtonOption";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCarChassis() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    car_chassis: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      car_chassis: "",
    };

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            What is the chassis number of your{" "}
            <b>
              {quote.item.car_make} {quote.item.car_model}
            </b>
            ?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Stack gap={2} direction={["column"]}>
              <TextField
                id="car_chassis"
                label="Chassis"
                variant="outlined"
                value={quote.item.car_chassis}
                fullWidth
                onChange={(e) =>
                  dispatch(
                    updateProperty({
                      prop: "car_chassis",
                      value: e.target.value,
                    })
                  )
                }
                error={errors.car_chassis !== ""}
                helperText={
                  errors.car_chassis !== ""
                    ? errors.car_chassis
                    : "You can find this on the papers of your vehicle"
                }
                disabled={isBusy}
              />
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.car_chassis || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>

      <Box sx={{ mt: 6 }}>
        <Stack gap={2}>
          <ButtonOption onClick={handleNextStep}>
            I don’t have access to my vehicle identification number.
            <br />I understand that I must provide this information to fully
            activate my insurance coverage.
          </ButtonOption>
        </Stack>
      </Box>
    </>
  );
}
