import React from "react";
import { useSelector, useDispatch } from "react-redux";

import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Components
import logo from "../../assets/logo-512-light.png";
import Agent from "./Agent";

// State
import { STEP_CHECKOUT, STEP_PAYMENT, STEP_FINISH } from "./steps";
import { setDialogOpen, previousStep } from "../../app/features/quoteSlice";

// Components
const ToolbarButton = ({
  label = "toolbar button",
  onClick,
  disabled,
  children,
}) => {
  return (
    <IconButton
      aria-label={label}
      onClick={onClick}
      sx={{
        opacity: "0.5",
        transition: "all 0.2s ease-in-out",
        ":hover": { opacity: 1 },
      }}
      //  size="small"
      disabled={disabled ? true : false}
    >
      {children}
    </IconButton>
  );
};

const DialogTitleWrapper = ({ children, disabled, disableBack }) => {
  const dispatch = useDispatch();

  return (
    <DialogTitle sx={{ borderTop: "5px solid", borderColor: "primary.main" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ToolbarButton
          label="Previous Step"
          onClick={() => dispatch(previousStep())}
          disabled={disabled || disableBack}
        >
          <ChevronLeftIcon />
        </ToolbarButton>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          {children}
        </Box>
        <ToolbarButton
          label="Close"
          onClick={() => dispatch(setDialogOpen(false))}
          disabled={disabled}
        >
          <CloseIcon />
        </ToolbarButton>
      </Box>
    </DialogTitle>
  );
};

//******************************************************************************
export default function DialogTitleQuote({ disabled }) {
  const quote = useSelector((state) => state.quote);

  if (quote.step === STEP_CHECKOUT || quote.item.paid_status) {
    return (
      <DialogTitleWrapper
        disabled={disabled}
        disableBack={quote.error ? false : true}
      >
        <Box
          sx={{
            textAlign: "center",
            pt: 1,
          }}
        >
          <img src={logo} alt="company-logo" style={{ maxHeight: "30px" }} />
        </Box>
      </DialogTitleWrapper>
    );
  }

  // Default Header
  return (
    <DialogTitleWrapper
      disabled={disabled}
      disableBack={quote.item.paid_status}
    >
      <Container maxWidth={"sm"}>
        <Agent showProfile />
      </Container>
    </DialogTitleWrapper>
  );
}
