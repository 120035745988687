import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Icons
import HomeIcon from "@mui/icons-material/Home";

// Components
import Layout from "../components/auth/Layout";
import AlertBar from "../components/AlertBar";

// State
import { STATUS_FAILED, STATUS_SUCCES } from "../app/status";
import {
  resetStatus,
  verifyEmail,
  setDialogOpen,
} from "../app/features/quoteSlice";
import { validateEmail } from "../functions/inputValidation";

//******************************************************************************
export default function NotFound() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isBusy, setIsBusy] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  const email = searchParams.get("email");
  const id_check = searchParams.get("uid");
  const quote_id = searchParams.get("quote");

  useEffect(() => {
    const verifyEmailById = () => {
      dispatch(
        verifyEmail({ email: email, id_check: id_check, quote_id: quote_id })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {})
        .catch((rejectedValueOrSerializedError) => {})
        .finally(() => setIsBusy(false));
    };

    if (email && id_check) {
      if (validateEmail(email)) verifyEmailById();
      else {
        setErrMsg(`'${email}' is not a valid email address.`);
        setIsBusy(false);
      }
    } else {
      setIsBusy(false);
    }

    return () => {
      dispatch(resetStatus());
      setIsBusy(true);
    };
  }, [email, id_check, quote_id]);

  return (
    <Layout
      title={
        isBusy
          ? "Verifying..."
          : !email || !id_check
          ? "Nothing to Verify"
          : quote.status === STATUS_FAILED
          ? "Error"
          : "Email Verified"
      }
      isLoading={isBusy}
    >
      <Box sx={{ textAlign: "center" }}>
        <AlertBar error={errMsg} onClose={() => setErrMsg("")} />
        <AlertBar error={quote.error} onClose={() => dispatch(resetStatus())} />

        {quote.status === STATUS_SUCCES ? (
          <>
            <Typography>Your email was successfully verified.</Typography>
            <Button
              onClick={() => {
                dispatch(setDialogOpen(true));
                navigate("/", { replace: true });
              }}
              variant="contained"
              sx={{ mt: 3 }}
            >
              Get a Quote
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            component={NavLink}
            to={"/"}
            startIcon={<HomeIcon />}
          >
            Go Home
          </Button>
        )}
      </Box>
    </Layout>
  );
}
