import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import ButtonLoading from "../ButtonLoading";
import ButtonOption from "./ButtonOption";

// State
import {
  validateFormErrors,
  animateForm,
  validateEmail,
} from "../../functions/inputValidation";
import {
  updateProperty,
  nextStep,
  setStep,
} from "../../app/features/quoteSlice";
import { STEP_USER_CONTACT_PHONE } from "./steps";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepUserContactEmail() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    phone: "",
    phone2: "",
    email: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      phone: "",
      phone2: "",
      email: "",
    };

    //if (quote.item.phone === "") tmpErr.phone = "Please enter your phone.";

    if (quote.item.email === "")
      tmpErr.email = "Please enter your email address.";
    else if (!validateEmail(quote.item.email))
      tmpErr.email = "Invalid email address.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[<>For optimal service please provide your email address</>]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              value={quote.item.email}
              fullWidth
              onChange={(e) =>
                dispatch(
                  updateProperty({
                    prop: "email",
                    value: e.target.value,
                  })
                )
              }
              error={errors.email !== ""}
              helperText={errors.email}
              disabled={
                quote.item.email_confirmed ||
                isBusy ||
                quote.status === STATUS_LOADING
              }
              inputProps={{ maxLength: 255 }}
            />
            <Box sx={{ textAlign: "right" }}>
              <ButtonLoading
                disabled={!quote.item.email}
                isLoading={isBusy || quote.status === STATUS_LOADING}
                onClick={handleNextStep}
              >
                Next
              </ButtonLoading>
            </Box>
          </Stack>
        </form>
      </DialogForm>
      {!quote.item.email && (
        <Box sx={{ mt: 6 }}>
          <Stack gap={2}>
            <ButtonOption
              onClick={() => dispatch(setStep(STEP_USER_CONTACT_PHONE))}
            >
              I don't have an email address.
            </ButtonOption>
          </Stack>
        </Box>
      )}
    </>
  );
}
