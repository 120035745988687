import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Icons
import congratulations from "../../assets/congratulations.svg";

// State
import { products } from "../../app/lists/products";
import { formatCurrency } from "../../functions/formatData";

//******************************************************************************
const ProductCheckoutWrapper = ({
  product,
  children,
  doPayment,
  disabled,
  paymentExtra,
}) => {
  const quote = useSelector((state) => state.quote);

  if (!product) return null;

  return (
    <>
      <Box
        sx={{ backgroundColor: "secondary.main", textAlign: "center", p: 3 }}
      >
        <Typography
          sx={{ fontSize: "1.2rem", color: "secondary.contrastText" }}
        >
          Congratulations{" "}
          <span style={{ fontWeight: "500" }}>{quote.item.first_name}</span>
        </Typography>
        <Box sx={{ p: 2 }}>
          <img
            src={congratulations}
            alt="insurance"
            style={{ maxHeight: "55px", height: "100%" }}
          />
        </Box>
        <Typography
          sx={{ fontSize: "1.2rem", color: "secondary.contrastText" }}
        >
          {product.name}
        </Typography>

        <Box> {children}</Box>
        <ButtonPayNow doPayment={doPayment} disabled={disabled} />

        {paymentExtra && (
          <Typography
            sx={{ fontSize: "1rem", color: "secondary.contrastText", py: 1 }}
          >
            {paymentExtra}
          </Typography>
        )}
      </Box>

      <Box
        sx={{ backgroundColor: "background.paper", textAlign: "center", p: 4 }}
      >
        <Typography
          sx={{ textTransform: "uppercase", fontSize: "1.3rem", mb: 3 }}
        >
          {product.name}
          <br />
          Cover Overview
        </Typography>
        <Grid container spacing={4}>
          {product.cover?.map((cover, index) => (
            <Grid
              key={index}
              item
              xs={4}
              sx={{ opacity: cover.included ? 1 : 0.2 }}
            >
              <img
                src={cover.image}
                alt={cover.label}
                style={{ width: "100%", maxWidth: "100px" }}
              />
              <Typography sx={{ fontSize: "1rem" }}>{cover.label}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box
        sx={{ backgroundColor: "secondary.main", textAlign: "center", p: 3 }}
      >
        <ButtonPayNow doPayment={doPayment} disabled={disabled} />
      </Box>
    </>
  );
};

const ButtonPayNow = ({ doPayment, disabled }) => {
  if (doPayment) {
    return (
      <Button
        variant="contained"
        sx={{ m: 2 }}
        onClick={() => doPayment()}
        disabled={disabled}
      >
        Buy Now
      </Button>
    );
  }

  return null;
};

//******************************************************************************
export default function ProductCheckout({ product, doPayment }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);
  const quote = useSelector((state) => state.quote);

  const [isBusy, setIsBusy] = useState(true);
  const [isSent, setIsSent] = useState(false);

  const [errors, setErrors] = useState({});

  switch (quote.item.product_type) {
    case "car":
      return (
        <ProductCheckoutWrapper
          product={product}
          doPayment={doPayment}
          paymentExtra="Your monthly premium is automatically charged to your bank card."
        >
          <Typography
            sx={{ fontSize: "1.2rem", color: "secondary.contrastText" }}
          >
            {quote.item.car_make + " " + quote.item.car_model}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: "secondary.contrastText",
              fontStyle: "italic",
              mb: 2,
            }}
          >
            {`Sum Insured: ${formatCurrency(
              Math.round(quote.item.car_value / 100),
              theme.theme.currency,
              theme.theme.language
            )}`}
          </Typography>
          <Typography
            sx={{
              fontSize: "2rem",
              color: "secondary.contrastText",
              pt: 1,
            }}
          >
            {formatCurrency(
              Math.round(quote.item.monthly / 100),
              theme.theme.currency,
              theme.theme.language
            )}{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "1rem",
              color: "secondary.contrastText",
              pb: 4,
            }}
          >
            Monthly Premium
          </Typography>
          {!quote.item.paid_at && (
            <Typography
              sx={{
                fontSize: "1.2rem",
                color: "secondary.contrastText",
                pb: 1,
              }}
            >
              {quote.item.product_type === "car"
                ? `Amount Payable - Enroll now with a 2-month deposit ${formatCurrency(
                    Math.round(quote.item.amount / 100),
                    theme.theme.currency,
                    theme.theme.language
                  )}`
                : "Sign up Now for 12 months coverage"}
            </Typography>
          )}
        </ProductCheckoutWrapper>
      );
    default:
      return (
        <ProductCheckoutWrapper product={product} doPayment={doPayment}>
          <Typography
            sx={{ fontSize: "2rem", color: "secondary.contrastText", p: 1 }}
          >
            {formatCurrency(
              Math.round(quote.item.annual / 100),
              theme.theme.currency,
              theme.theme.language
            )}{" "}
            <span style={{ fontSize: "0.9rem", fontWeight: 400 }}>
              once off
            </span>
          </Typography>
          {!quote.item.paid_at && (
            <Typography
              sx={{ fontSize: "1rem", color: "secondary.contrastText", pb: 1 }}
            >
              Sign up Now for 12 months coverage
            </Typography>
          )}
        </ProductCheckoutWrapper>
      );
  }
}
