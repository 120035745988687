import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import jdpApi from "../jdpApi";
import { handleAxiosError } from "../../functions/errorWithMessage";

import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCES,
  STATUS_FAILED,
  STATUS_UPDATE,
} from "../status";

// Default Logos
import faviconLight from "../../assets/favicon-light.png";
import faviconDark from "../../assets/favicon-dark.png";
import logoLight from "../../assets/logo-light.png";
import logoDark from "../../assets/logo-dark.png";

const API_ROOT = "/theme";

const defaultTheme = {
  favicon: { light: faviconLight, dark: faviconDark },
  logo: { light: logoLight, dark: logoDark },
  language: "en-NG",
  currency: "NGN",
  palette: {
    primary: {
      main: "#409d9f",
    },
    secondary: {
      main: "#409d9f",
    },
    background: {
      default: "#eee",
      paper: "rgba(255, 255, 255, 1)",
    },
  },
};

const defaultLegal = {
  terms: STATUS_IDLE,
  privacy: STATUS_IDLE,
  disclaimer: STATUS_IDLE,
  cookies: STATUS_IDLE,
};

// Initialize
export const initializeTheme = createAsyncThunk(
  "theme/initializeTheme",
  async (_, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.get(`${API_ROOT}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }

    // try {
    //   // Validate all the values
    //   output.siteName = "The System";

    //   return output;
    // } catch (error) {
    //   return thunkAPI.rejectWithValue(error.message);
    // }
  }
);

export const getLegal = createAsyncThunk(
  "theme/getLegal",
  async ({ page }, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.get(`${API_ROOT}/legal/${page}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Structure of initial state
const initialState = {
  id: 0,
  siteName: process.env.REACT_APP_NAME,
  theme: defaultTheme,
  legal: defaultLegal,
  allowRegister: false,
  roles: [],
  status: STATUS_IDLE,
  error: "",
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  // Actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Initialize theme via stored data
      .addCase(initializeTheme.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(initializeTheme.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload.id) state.id = action.payload.id;
          if (action.payload.company) state.siteName = action.payload.company;
          // if (action.payload.site.roles) {
          //   let roles = [];
          //   if (Array.isArray(action.payload.site.roles)) {
          //     action.payload.site.roles.forEach((role) => {
          //       if (role.role_id && role.role) {
          //         let newrole = {
          //           id: role.role_id,
          //           name: role.role,
          //         };
          //         roles.push(newrole);
          //       }
          //     });
          //   }
          //   state.roles = roles;
          // }

          // Favicon
          if (action.payload.theme.favicon.light)
            state.theme.favicon.light = action.payload.theme.favicon.light;
          if (action.payload.theme.favicon.dark)
            state.theme.favicon.dark = action.payload.theme.favicon.dark;

          // Logos
          if (action.payload.theme.logo.light)
            state.theme.logo.light = action.payload.theme.logo.light;
          if (action.payload.theme.logo.dark)
            state.theme.logo.dark = action.payload.theme.logo.dark;

          // Colors
          if (action.payload.theme.palette) {
            if (action.payload.theme.palette.primary.main)
              state.theme.palette.primary.main =
                action.payload.theme.palette.primary.main;
            if (action.payload.theme.palette.secondary.main)
              state.theme.palette.secondary.main =
                action.payload.theme.palette.secondary.main;
            if (action.payload.theme.palette.background.default)
              state.theme.palette.background.default =
                action.payload.theme.palette.background.default;
            if (action.payload.theme.palette.background.paper)
              state.theme.palette.background.paper =
                action.payload.theme.palette.background.paper;
          }
        }

        state.status = STATUS_SUCCES;
      })
      .addCase(initializeTheme.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Get legal information
      .addCase(getLegal.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(getLegal.fulfilled, (state, action) => {
        if (action.payload.terms) state.legal.terms = action.payload.terms;
        if (action.payload.privacy)
          state.legal.privacy = action.payload.privacy;
        if (action.payload.disclaimer)
          state.legal.disclaimer = action.payload.disclaimer;
        if (action.payload.cookies)
          state.legal.cookies = action.payload.cookies;

        state.status = STATUS_SUCCES;
      })
      .addCase(getLegal.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      });
  },
});

export const {} = themeSlice.actions;

export default themeSlice.reducer;
