import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

// Components
import logo from "../../assets/logo.png";
import Layout from "./LayoutMain";

// State
import { STATUS_LOADING } from "../../app/status";
import { resetStatus } from "../../app/features/authSlice";

//******************************************************************************
export default function LayoutMain({
  appTitle = process.env.REACT_APP_NAME,
  title,
  children,
  options,
  isLoading,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <Layout appTitle={appTitle} title={title}>
      <Box sx={{ backgroundColor: "background.default", py: 4 }}>
        <Container maxWidth="sm">
          <Typography variant="h1">{title}</Typography>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "background.paper", py: 4 }}>
        <Container maxWidth="sm">{children}</Container>
      </Box>
    </Layout>
  );
}
