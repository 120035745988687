import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useSearchParams, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import Link from "@mui/material/Link";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { setDialogOpen } from "../../app/features/quoteSlice";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepFinish({
  values,
  setValues,
  handleChange,
  nextStep,
}) {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    setValues({ ...values, car_insurance_claims: value });

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      nextStep();
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Thank you <b>{quote.item.first_name}</b>!
          </>,
          <>You are now covered!</>,
        ]}
      />

      <Box sx={{ mt: 6 }}>
        <Box sx={{ textAlign: "right" }}>
          <Button
            variant={"contained"}
            sx={{ minWidth: "150px" }}
            onClick={() => dispatch(setDialogOpen(false))}
          >
            Close
          </Button>
        </Box>
      </Box>
    </>
  );
}
