import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserOccupation() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    occupation: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      occupation: "",
    };

    if (quote.item.occupation === "")
      tmpErr.occupation = "Please select your occupation.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  const setOccupation = (newValue) => {
    dispatch(updateProperty({ prop: "occupation", value: newValue }));
    setErrors({ ...errors, occupation: "" });
  };
  const [occupationInput, setOccupationInput] = useState("");
  const setOccupationInputValue = (newValue) => {
    setOccupationInput(newValue);
  };

  const occupations = [
    "Accountant",
    "Actor/Actress",
    "Administrator",
    "Architect",
    "Artist",
    "Attorney/Lawyer",
    "Baker",
    "Banker",
    "Barista",
    "Bartender",
    "Biologist",
    "Business Owner/Entrepreneur",
    "Carpenter",
    "Chef",
    "Cleaner/Housekeeper",
    "Consultant",
    "Dentist",
    "Designer (e.g., Fashion, Interior)",
    "Doctor",
    "Electrician",
    "Engineer (e.g., Civil, Mechanical)",
    "Farmer/Agriculturalist",
    "Firefighter",
    "Fitness Instructor/Personal Trainer",
    "Florist",
    "Graphic Designer",
    "Hairdresser/Barber",
    "Journalist",
    "Librarian",
    "Mechanic",
    "Nurse",
    "Painter/Artist",
    "Pharmacist",
    "Photographer",
    "Pilot/Aviator",
    "Plumber",
    "Police Officer/Law Enforcement",
    "Professor/Lecturer",
    "Receptionist",
    "Retired",
    "Salesperson (e.g., Retail, Real Estate)",
    "Scientist (e.g., Biologist, Chemist)",
    "Secretary/Office Assistant",
    "Software Developer/Programmer",
    "Student",
    "Teacher/Educator",
    "Technician (e.g., IT, Laboratory)",
    "Translator/Interpreter",
    "Unemployed",
    "Veterinarian",
    "Waiter/Waitress",
    "Writer/Author",
    "Yoga Instructor/Wellness Coach",
    "Zoologist/Animal Behaviorist",
    "Other (Please specify)",
  ];

  return (
    <>
      <DialogIntro messages={[<>What is your occupation?</>]} />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Autocomplete
              disablePortal
              id="occupation"
              options={occupations}
              value={quote.item.occupation ? quote.item.occupation : undefined}
              onChange={(event, newValue) => {
                setOccupation(newValue);
              }}
              inputValue={occupationInput}
              onInputChange={(event, newInputValue) => {
                setOccupationInputValue(newInputValue);
              }}
              fullWidth
              sx={{ flex: 4 }}
              renderInput={(params) => (
                <TextField {...params} label="Occupation" />
              )}
            />

            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.occupation || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
