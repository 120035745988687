import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";

//******************************************************************************
export default function StepProductDetails({
  values,
  setValues,
  handleChange,
  nextStep,
}) {
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    car_registration: "",
    car_chassis: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      car_registration: "",
      car_chassis: "",
    };

    if (values.registration === "")
      tmpErr.registration = "Please enter your car's registration number.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      nextStep();
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            What is your{" "}
            <b>
              {values.car_year} {values.car_make} {values.car_model}'s
            </b>{" "}
            registration?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Stack gap={2} direction={["column"]}>
              <TextField
                id="car_registration"
                label="Registration"
                variant="outlined"
                value={values.car_registration}
                fullWidth
                onChange={handleChange("car_registration")}
                error={errors.car_registration !== ""}
                helperText={errors.car_registration}
                required
                disabled={isBusy}
              />
              {values.car_registration && (
                <>
                  <TextField
                    id="car_chassis"
                    label="Chassis"
                    variant="outlined"
                    value={values.car_chassis}
                    fullWidth
                    onChange={handleChange("car_chassis")}
                    error={errors.car_chassis !== ""}
                    helperText={errors.car_chassis}
                    disabled={isBusy}
                  />
                </>
              )}
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!values.car_registration || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
