import { parseData } from "../../functions/parseData";

// Initialize Object from Data
export const clUser = (data) => {
  let output = {
    id: 0,
    username: "",
    email: "",
    email_confirmed: false,
    avatar: "",
    first_name: "",
    last_name: "",
    mobile: "",
    mobile_confirmed: false,
    phone: "",
    phone_confirmed: false,
    is_admin: false,
    last_login: null,
    date_joined: null,
  };
  let outputType = {
    id: "integer",
    username: "string",
    email: "string",
    email_confirmed: "bool",
    avatar: "string",
    first_name: "string",
    last_name: "string",
    mobile: "string",
    mobile_confirmed: "bool",
    phone: "string",
    phone_confirmed: "bool",
    is_admin: "bool",
    last_login: "date",
    date_joined: "date",
  };

  if (data) {
    output = parseData(output, outputType, data);
  }
  return output;
};
