import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";

// Icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

// State
import { setDialogOpen as setDialogContactOpen } from "../app/features/contactSlice";

//******************************************************************************
export default function HelpButton({ caption = "Help" }) {
  const dispatch = useDispatch();
  return (
    <div
      className="help-button"
      onClick={() => dispatch(setDialogContactOpen(true))}
    >
      {/* <HelpOutlineIcon style={{ fontSize: "1.3rem" }} /> */}
      <Box sx={{ paddingLeft: "5px", color: "white" }}>{caption}</Box>
    </div>
  );
}
