import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import ProductItem from "../products/ProductItem";

// State
import { products } from "../../app/lists/products";
import { updateProperty } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepWhatToInsure() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  return (
    <>
      <DialogIntro
        messages={[
          <>
            How can I help you <b>{quote.item.first_name}</b>
          </>,
        ]}
      />

      <Box sx={{ mt: 2 }}>
        <Stack gap={2}>
          {products.map((product, index) => (
            <ProductItem
              key={index}
              product={product}
              onClick={() => {
                dispatch(
                  updateProperty({
                    prop: "product_type",
                    value: product.product_type,
                  })
                );
              }}
            />
          ))}
          {/* <Button
            variant="contained"
            onClick={() =>
              dispatch(updateProperty({ prop: "product_type", value: "car" }))
            }
          >
            Car Insurrance
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              dispatch(updateProperty({ prop: "product_type", value: "third" }))
            }
          >
            Third Party Insurance
          </Button> */}
        </Stack>
      </Box>
    </>
  );
}
