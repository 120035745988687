import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import ButtonOption from "./ButtonOption";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import {
  updateProperty,
  nextStep,
  setStep,
} from "../../app/features/quoteSlice";
import { STEP_CAR_CHASSIS } from "./steps";

//******************************************************************************
export default function StepCarRegistration() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    car_registration: "",
    car_chassis: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      car_registration: "",
      car_chassis: "",
    };

    if (quote.item.registration === "")
      tmpErr.registration = "Please enter your car's registration number.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            What is the registration number of your{" "}
            <b>
              {quote.item.car_make} {quote.item.car_model}
            </b>
            ?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Stack gap={2} direction={["column"]}>
              {/* <Autocomplete
                disablePortal
                id="car_country"
                options={carCountry}
                value={
                  quote.item.car_country ? quote.item.car_country : undefined
                }
                onChange={(event, newValue) => {
                  setCarCountry(newValue);
                }}
                inputValue={carCountryInput}
                onInputChange={(event, newInputValue) => {
                  setCarCountryInputValue(newInputValue);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                fullWidth
                sx={{ flex: 4 }}
                renderInput={(params) => (
                  <TextField {...params} label="Country of Registration" />
                )}
              /> */}

              <TextField
                id="car_registration"
                label="License Plate Number"
                variant="outlined"
                value={quote.item.car_registration ?? undefined}
                fullWidth
                onChange={(e) =>
                  dispatch(
                    updateProperty({
                      prop: "car_registration",
                      value: e.target.value,
                    })
                  )
                }
                error={errors.car_registration !== ""}
                helperText={errors.car_registration}
                required
                disabled={isBusy}
              />
              {/*              
              {quote.item.car_registration && (
                <>
                  <FormControl>
                    <FormLabel id="car-condition">
                      Condition of your car
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="car-condition"
                      name="car-condition-selection"
                      value={quote.item.car_condition}
                      onChange={(e) =>
                        dispatch(
                          updateProperty({
                            prop: "car_condition",
                            value: e.target.value,
                          })
                        )
                      }
                      sx={{ ml: 2 }}
                    >
                      {carCondition.map((condition) => (
                        <FormControlLabel
                          key={condition}
                          value={condition}
                          control={<Radio />}
                          label={condition}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </>
              )}
               */}
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.car_registration || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
      {!quote.item.car_registration && (
        <Box sx={{ mt: 6 }}>
          <Stack gap={2}>
            <ButtonOption onClick={() => dispatch(setStep(STEP_CAR_CHASSIS))}>
              I understand that I must provide this information to fully
              activate my insurance coverage.
            </ButtonOption>
          </Stack>
        </Box>
      )}
    </>
  );
}
