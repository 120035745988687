import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import ButtonLoading from "../ButtonLoading";
import ButtonOption from "./ButtonOption";

// State
import { products } from "../../app/lists/products";
import jdpAuthApi from "../../app/jdpApi";
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";

import { updateProperty, nextStep } from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepCar() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  const [isBusy, setIsBusy] = useState(false);
  const [isCarBusy, setIsCarBusy] = useState(true);

  const [errors, setErrors] = useState({
    car_year: "",
    car_make: "",
    car_model: "",
    car_country: "",
  });

  // Complete step
  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      car_year: "",
      car_make: "",
      car_model: "",
      car_country: "",
    };

    if (quote.item.car_year === "")
      tmpErr.car_year = "Please select the year your was manufactured.";
    if (quote.item.car_make === "")
      tmpErr.car_make = "Please select the brand of your car.";
    if (quote.item.car_model === "")
      tmpErr.car_model = "Please describe the model of your car.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  // Update car year
  const setCarYear = (newValue) => {
    dispatch(updateProperty({ prop: "car_year", value: newValue }));
    //dispatch(updateProperty({ prop: "car_make", value: "" }));
    setCarMake();
    //dispatch(updateProperty({ prop: "car_model", value: "" }));

    // setValues({ ...values, car_make: newValue });
    setErrors({ ...errors, car_year: "" });
  };

  // Get available car years
  const [carYears, setCarYears] = useState([]);
  const getCarYears = async () => {
    try {
      const response = await jdpAuthApi
        .get(`/car`)
        .finally(() => setIsCarBusy(false));
      setCarYears(response.data);
    } catch (error) {
      setIsBusy(false);
    }
  };

  // Get makes once year is selected
  useEffect(() => {
    if (quote.item.car_year) {
      setIsCarBusy(true);
      getCarMakes();
    }
  }, [quote.item.car_year]);

  // Handle Car Make and Models
  const setCarMake = (newValue) => {
    dispatch(updateProperty({ prop: "car_make", value: newValue }));
    dispatch(updateProperty({ prop: "car_model", value: "" }));
    // setValues({ ...values, car_make: newValue });
    setErrors({ ...errors, car_make: "" });
  };

  const [carMakeInput, setCarMakeInput] = useState("");
  const setCarMakeInputValue = (newValue) => {
    setCarMakeInput(newValue);
  };

  // Get car makes by year
  const [carMakes, setCarMakes] = useState([]);
  const getCarMakes = async () => {
    try {
      const response = await jdpAuthApi
        .get(`/car?year=${quote.item.car_year}`)
        .finally(() => setIsCarBusy(false));
      setCarMakes(response.data);
    } catch (error) {
      setIsBusy(false);
    }
  };

  // Get models once make is selected
  useEffect(() => {
    if (quote.item.car_make) {
      setIsCarBusy(true);
      getCarModels();
    }
  }, [quote.item.car_make]);

  // Handle Car models
  const setCarModel = (newValue) => {
    dispatch(updateProperty({ prop: "car_model", value: newValue }));
    // setValues({ ...values, car_make: newValue });
    setErrors({ ...errors, car_model: "" });
  };
  const [carModelInput, setCarModelInput] = useState("");
  const setCarModelInputValue = (newValue) => {
    setCarModelInput(newValue);
  };

  const [carModels, setCarModels] = useState([]);

  const getCarModels = async () => {
    try {
      const response = await jdpAuthApi
        .get(`/car/${quote.item.car_make}?year=${quote.item.car_year}`)
        .finally(() => setIsCarBusy(false));
      setCarModels(response.data);
    } catch (error) {
      setIsBusy(false);
    }
  };

  // const carYearOptions = () => {
  //   let output = [];
  //   for (let i = 2024; i > 2000; i--) {
  //     output.push(i);
  //   }

  //   return output;
  // };

  // Initialize
  useEffect(() => {
    setIsCarBusy(true);
    getCarYears();
    //getCarMakes();
  }, []);

  return (
    <>
      <DialogIntro
        messages={[
          // <>
          //   Hi <b>{quote.item.first_name}</b>.
          // </>,
          <>
            Hi <b>{quote.item.first_name}</b>. Please provide some information
            about the car you're looking to insure.
          </>,
        ]}
      />

      <DialogForm
        messages={[
          quote.item.address1 && (
            <>
              I would like to insure my{" "}
              <b>
                {quote.item.car_year +
                  " " +
                  quote.item.car_make +
                  " " +
                  quote.item.car_model}
              </b>{" "}
            </>
          ),
        ]}
      >
        <form id={"editForm"}>
          <Stack gap={2}>
            <Stack gap={2} direction={["column", "row"]}>
              <FormControl fullWidth sx={{ flex: 1 }}>
                <InputLabel id="car-manufacture-year">
                  Year of manufacture
                </InputLabel>
                <Select
                  labelId="car-manufacture-year"
                  id="car_year"
                  value={
                    quote.item.car_year === 0 ? undefined : quote.item.car_year
                  }
                  disabled={isBusy || isCarBusy}
                  label="Year of manufacture"
                  onChange={(e) => setCarYear(e.target.value)}
                >
                  {carYears.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>

                {/* <Select
                  labelId="car-manufacture-year"
                  id="car_year"
                  value={
                    quote.item.car_year === 0 ? undefined : quote.item.car_year
                  }
                  disabled={isBusy || isCarBusy}
                  label="Year of manufacture"
                  onChange={(e) =>
                    dispatch(
                      updateProperty({
                        prop: "car_year",
                        value: e.target.value,
                      })
                    )
                  }
                >
                  {carYearOptions().map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
              {quote.item.car_year > 0 && (
                <Autocomplete
                  disablePortal
                  id="car_make"
                  options={carMakes}
                  value={quote.item.car_make ? quote.item.car_make : undefined}
                  onChange={(event, newValue) => {
                    setCarMake(newValue);
                  }}
                  inputValue={carMakeInput}
                  onInputChange={(event, newInputValue) => {
                    setCarMakeInputValue(newInputValue);
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.label === quote.item.label
                  // }
                  disabled={isBusy || isCarBusy}
                  fullWidth
                  sx={{ flex: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Make" />
                  )}
                />
              )}

              {quote.item.car_make && (
                <Autocomplete
                  disablePortal
                  id="car_model"
                  options={carModels}
                  value={
                    quote.item.car_model ? quote.item.car_model : undefined
                  }
                  onChange={(event, newValue) => {
                    setCarModel(newValue);
                  }}
                  inputValue={carModelInput}
                  onInputChange={(event, newInputValue) => {
                    setCarModelInputValue(newInputValue);
                  }}
                  // isOptionEqualToValue={(option, value) =>
                  //   option.label === quote.item.label
                  // }
                  fullWidth
                  disabled={isBusy || isCarBusy}
                  sx={{ flex: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Model" />
                  )}
                />
              )}
            </Stack>

            <Box sx={{ textAlign: "right" }}>
              <ButtonLoading
                disabled={
                  !quote.item.car_year ||
                  !quote.item.car_make ||
                  !quote.item.car_model ||
                  isBusy
                }
                isLoading={
                  isBusy || isCarBusy || quote.status === STATUS_LOADING
                }
                onClick={handleNextStep}
              >
                Next
              </ButtonLoading>
            </Box>
          </Stack>
        </form>
      </DialogForm>

      <Box sx={{ mt: 6 }}>
        <Stack gap={2}>
          <ButtonOption
            onClick={() =>
              dispatch(updateProperty({ prop: "product_type", value: null }))
            }
          >
            Oops! I do not want{" "}
            {products
              .filter((item) => item.product_type === quote.item.product_type)
              .map((item) => item.name)}
          </ButtonOption>
        </Stack>
      </Box>
    </>
  );

  //   return (
  //     <>
  //       <DialogIntro
  //         messages={[
  //           <>
  //             How can I help you <b>{quote.item.first_name}</b>
  //           </>,
  //         ]}
  //       />

  //       <Box sx={{ mt: 6 }}>
  //         <Stack gap={2}>
  //           <Button
  //             variant="contained"
  //             onClick={() => setValues({ ...values, product_type: "car" })}
  //           >
  //             Car Insurrance
  //           </Button>
  //           <Button
  //             variant="contained"
  //             onClick={() => setValues({ ...values, product_type: "third" })}
  //           >
  //             Third Party Insurance
  //           </Button>
  //         </Stack>
  //       </Box>
  //     </>
  //   );
}
