import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { NavLink } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

// Icons
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

// Components
import DialogEdit from "../DialogEdit";
import DialogConfirm from "../DialogConfirm";
import FormContact from "./FormContact";
import AlertBar from "../../components/AlertBar";

// State
import { setDialogOpen } from "../../app/features/contactSlice";
import jdpApi from "../../app/jdpApi";
import { handleAxiosError } from "../../functions/errorWithMessage";

import {
  validateEmail,
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { resetStatus, sendEmail } from "../../app/features/contactSlice";

//******************************************************************************
export default function DialogContact() {
  const dispatch = useDispatch();
  const contact = useSelector((state) => state.contact);

  const auth = useSelector((state) => state.auth);
  const quote = useSelector((state) => state.quote.item);
  const [isBusy, setIsBusy] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [isSend, setIsSend] = useState(false);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    if (!values.name) {
      setValues({
        ...values,
        name: quote.first_name + " " + quote.last_name,
      });
    }

    if (values.email === "") {
      setValues({
        ...values,
        email: quote.email,
      });
    }

    if (values.phone === "") {
      setValues({
        ...values,
        phone: quote.phone,
      });
    }

    return () => {};
  }, [quote]);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
    setErrors({ ...errors, [prop]: "" });
  };

  const sendEmailOld = async () => {
    setErrMsg();

    const response = await jdpApi
      .post("/contact", values)
      .then(function (response) {
        // handle success
        setIsSend(true);
      })
      .catch(function (error) {
        // handle error
        animateForm("contactForm");
        setErrMsg(handleAxiosError(error));
      })
      .finally(function () {
        // always executed
        setIsBusy(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsBusy(true);

    const tmpErr = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };

    // Validate the fields
    if (values.name === "") tmpErr.name = "Please enter your name.";
    if (values.subject === "") tmpErr.subject = "Please enter a subject.";
    if (values.message === "") tmpErr.message = "Please enter your message.";

    // Validate email
    if (values.email === "") tmpErr.email = "Please enter your email address.";
    else if (!validateEmail(values.email))
      tmpErr.email = "Invalid email address.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      //sendEmail();
      dispatch(sendEmail(values))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsSend(true);
        })
        .catch((rejectedValueOrSerializedError) => {
          //Something went wrong
          animateForm("contactForm");
        })
        .finally(function () {
          // always executed
          setIsBusy(false);
        });
    } else {
      // Something went wrong
      animateForm("contactForm");
      setIsBusy(false);
    }
  };

  if (isSend) {
    return (
      <DialogConfirm
        title="Message Sent Successfully"
        open={contact.dialog_open}
        onClose={() => dispatch(setDialogOpen(false))}
        actions={
          <>
            <Button
              onClick={() => dispatch(setDialogOpen(false))}
              variant="contained"
            >
              Close
            </Button>
          </>
        }
        maxWidth="xs"
      >
        <Typography variant="h3" gutterBottom>
          Your message has been sent.
        </Typography>
        <Typography variant="h5">
          An agent will respond to you shortly.
        </Typography>
      </DialogConfirm>
    );
  }

  return (
    <DialogEdit
      title="Contact Us"
      open={contact.dialog_open}
      onClose={() => dispatch(setDialogOpen(false))}
      onSubmit={handleSubmit}
      onSubmitCaption="Send"
      error={contact.error}
      resetError={() => dispatch(resetStatus())}
      isBusy={isBusy}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={12} md={4}>
          <Typography
            sx={{
              pb: 2,
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            If you have any questions or need to file a claim, please feel free
            to contact us:
          </Typography>
          <Stack direction={"row"} sx={{ pb: 3 }}>
            <Stack gap={2} sx={{ flex: 1 }}>
              <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }}>
                <LocalPhoneIcon color={"primary"} fontSize="0.9rem" />
                <Link href="tel:0800 255 4269" underline="hover">
                  0800 255 4269
                </Link>
              </Stack>
              <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }}>
                <EmailIcon color={"primary"} fontSize="0.9rem" />
                <Link href="mailto:info@allianz.ng" underline="hover">
                  info@allianz.ng{" "}
                </Link>
              </Stack>
              <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }}>
                <PlaceIcon fontSize="0.9rem" />
                <Box>95 Broad Street, Lagos</Box>
              </Stack>
              <Stack gap={1} direction={"row"} sx={{ alignItems: "center" }}>
                <DriveFileRenameOutlineIcon
                  color={"primary"}
                  fontSize="0.9rem"
                />
                <Link
                  href="https://app-web.allianz.com/AllianzEforms/Claims/motorClaim.html"
                  underline="hover"
                >
                  Log a claim online
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <form id="contactForm" noValidate onSubmit={handleSubmit}>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <TextField
                id="name"
                label="Name"
                type="text"
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name !== ""}
                helperText={errors.name}
                required
                disabled={isBusy}
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                id="email"
                label="Email"
                type="email"
                value={values.email}
                onChange={handleChange("email")}
                error={errors.email !== ""}
                helperText={errors.email}
                required
                disabled={isBusy}
                fullWidth
                inputProps={{ maxLength: 255 }}
              />
              <TextField
                id="phone"
                label="Phone"
                type="tel"
                value={values.phone}
                onChange={handleChange("phone")}
                error={errors.phone !== ""}
                helperText={errors.phone}
                disabled={isBusy}
                fullWidth
                inputProps={{ maxLength: 20 }}
              />
              <TextField
                id="subject"
                label="Subject"
                type="text"
                value={values.subject}
                onChange={handleChange("subject")}
                error={errors.subject !== ""}
                helperText={errors.subject}
                disabled={isBusy}
                fullWidth
                inputProps={{ maxLength: 150 }}
              />
              <TextField
                id="message"
                label="Message"
                type="text"
                value={values.message}
                onChange={handleChange("message")}
                error={errors.message !== ""}
                helperText={errors.message}
                disabled={isBusy}
                fullWidth
                InputProps={{
                  rows: 5,
                  multiline: true,
                  inputComponent: "textarea",
                }}
              />

              <AlertBar error={errMsg} onClose={() => setErrMsg()} />
            </Stack>
          </form>
        </Grid>
      </Grid>
    </DialogEdit>
  );
}
