import car from "../../assets/allianz_car.svg";
import third from "../../assets/allianz_third_party.svg";

// Icons
import congratulations from "../../assets/congratulations.svg";

import accident from "../../assets/allianz_accident.svg";
import thirdparty from "../../assets/allianz_third_party.svg";
import theft from "../../assets/allianz_theft.svg";
import assistance from "../../assets/allianz_assistance.svg";
import illegal from "../../assets/allianz_illegal.svg";
import business from "../../assets/allianz_business.svg";
import maintenance from "../../assets/allianz_service.svg";
import damage from "../../assets/allianz_condition.svg";
import nature from "../../assets/allianz_nature.svg";

export const products = [
  {
    name: "Comprehensive Motor Insurance",
    image: car,
    product_type: "car",
    cover: [
      {
        label: "Accident",
        image: accident,
        included: true,
      },
      {
        label: "Theft",
        image: theft,
        included: true,
      },
      {
        label: "Mother Nature",
        image: nature,
        included: true,
      },
      { label: "Damage to Others", image: thirdparty, included: true },
      {
        label: "Roadside Assistance",
        image: assistance,
        included: true,
      },
      { label: "Business Use", image: business, included: false },
      { label: "Illegal Use", image: illegal, included: false },
      { label: "Maintenance", image: maintenance, included: false },
      { label: "Pre-existing Damage", image: damage, included: false },
    ],
  },
  {
    name: "Third-Party Motor Insurance",
    image: third,
    product_type: "third",
    cover: [
      { label: "Damage to Others", image: thirdparty, included: true },
      {
        label: "Accident",
        image: accident,
        included: false,
      },
      {
        label: "Theft",
        image: theft,
        included: false,
      },
      {
        label: "Mother Nature",
        image: nature,
        included: false,
      },
      {
        label: "Roadside Assistance",
        image: assistance,
        included: false,
      },
      { label: "Business Use", image: business, included: false },
      { label: "Illegal Use", image: illegal, included: false },
      { label: "Maintenance", image: maintenance, included: false },
      { label: "Pre-existing Damage", image: damage, included: false },
    ],
  },
];
