import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

// Icons
import logocr from "../assets/logo-credit-registry.png";
import logops from "../assets/logo-paystack.png";
import logotransboxx from "../assets/logo-transboxx.png";

import homeimage from "../assets/black-mother-car.jpg";

// Components
import Layout from "../components/layout/LayoutMain";
import AccordionQuestions from "../components/AccordionQuestions";
import HelpButton from "../components/HelpButton";
import ProductItem from "../components/products/ProductItem";

// State
import { products } from "../app/lists/products";
import { setDialogOpen, updateProperty } from "../app/features/quoteSlice";
import { STEP_CHECKOUT } from "../components/quote/steps";
import { setDialogOpen as setOpenContactDialogOpen } from "../app/features/contactSlice";

//******************************************************************************
export default function Home() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  return (
    <>
      <Layout>
        <Box
          sx={{
            backgroundColor: "background.paper",
            minHeight: ["calc(100vh - 56px)", "calc(100vh - 64px)"],
            //** Full screen background */
            // backgroundSize: "cover",
            // backgroundPosition: "center",
            // backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.8)), url(${homeimage})`,
            width: "100%",
          }}
        >
          <Container sx={{ height: "100%", p: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: ["column-reverse", "column-reverse", "row"],
                alignItems: "center",
                minHeight: ["calc(100vh - 150px)"],
              }}
              columnGap={4}
            >
              <Box
                sx={{
                  textAlign: ["center", "center", "left"],
                  flex: 1,
                }}
              >
                <Box
                  sx={{
                    fontSize: "2.2rem",
                    fontWeight: "800",
                    color: "secondary.main",
                    lineHeight: "2.5rem",
                    marginBottom: 2,
                    marginTop: [2, 0],
                  }}
                >
                  Welcome to Allianz Nigeria
                </Box>
                <Box
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "300",
                    lineHeight: "2rem",
                  }}
                >
                  Insurance policies have never been more affordable.
                </Box>

                <Typography
                  sx={{ fontSize: "1.1rem", fontWeight: "400", mt: 4 }}
                >
                  <b>Premiums</b> are as <b>low</b> as NGN29,100 <b>Monthly</b>{" "}
                  for car values in range of <b>NGN5 million</b>!
                </Typography>

                <Stack gap={3} sx={{ my: [4, 6] }}>
                  {products.map((product, index) => (
                    <ProductItem
                      key={index}
                      product={product}
                      onClick={() => {
                        dispatch(
                          updateProperty({
                            prop: "product_type",
                            value: product.product_type,
                          })
                        );
                        dispatch(setDialogOpen(true));
                      }}
                      disabled={
                        quote.item.paid_status &&
                        product.product_type !== quote.item.product_type
                      }
                    />
                  ))}
                </Stack>

                {/* <Button
      variant="contained"
      sx={{
        mt: 4,
        fontSize: "1.2rem",
        padding: "15px 40px",
        borderRadius: "40px",
      }}
      onClick={() => dispatch(setDialogOpen(true))}
    >
      Get a Quote
    </Button>

    <Stack direction={"row"} gap={2} sx={{ mt: 4 }}>
      <Box>
        <IconButton
          color="primary"
          href={"https://facebook.com/WallaFilmsZA"}
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            width: "48px",
            height: "48px",
          }}
        >
          <FacebookIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          color="primary"
          href={"https://www.instagram.com/walla_films"}
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            width: "48px",
            height: "48px",
          }}
        >
          <InstagramIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
      </Box>
    </Stack> */}
              </Box>
              {/* Desktop */}
              <Box
                sx={{
                  display: ["none", "none", "block"],
                  flex: 1,
                  width: ["100%", "100%", "50%"],
                  alignSelf: "stretch",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${homeimage})`,
                }}
              >
                <Box
                  sx={
                    {
                      // backgroundSize: "cover",
                      // backgroundPosition: "center",
                      // backgroundImage: `url(${homeimage})`,
                      // height: "100vh",
                      // width: "100%",
                    }
                  }
                >
                  {/* <img src={homeimage} alt={"logo"} style={{ width: "100%" }} /> */}
                </Box>
              </Box>
              {/* Mobile */}
              <Box
                sx={{
                  display: ["block", "block", "none"],
                  flex: 1,
                  width: ["100%", "100%", "50%"],
                  alignSelf: "stretch",
                }}
              >
                <Box
                  sx={
                    {
                      // backgroundSize: "cover",
                      // backgroundPosition: "center",
                      // backgroundImage: `url(${homeimage})`,
                      // height: "100vh",
                      // width: "100%",
                    }
                  }
                >
                  <img src={homeimage} alt={"logo"} style={{ width: "100%" }} />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>

        <Box
          sx={{
            backgroundColor: "#aac7e5",
            color: "primary.contrastText",
            pl: [1, 4],
            pr: [1, 4],
            pt: 4,
            pb: 4,
            textAlign: "center",
          }}
        >
          <Container maxWidth="md">
            <Box
              sx={{
                letterSpacing: "2px",
                fontWeight: 300,
                fontSize: "1.7rem",
                mb: 3,
                color: "secondary.main",
              }}
            >
              Questions?
            </Box>

            <AccordionQuestions
              items={[
                {
                  question: "What type of motor insurance do you offer?",
                  answer:
                    "We offer comprehensive motor insurance which protects you, your car and any third party damage caused by you.",
                },
                {
                  question: "Can I pay for the policy monthly?",
                  answer:
                    "Yes, you can choose to pay for your policy monthly for added flexibility. This allows you to manage your finances more effectively without compromising on insurance coverage.",
                },
                {
                  question:
                    "Can I set up automatic debit for my monthly payments?",
                  answer:
                    "You have the option to set up automatic debit through our platform using Paystack. This ensures that your monthly payments are processed seamlessly without any manual intervention.",
                },
                {
                  question: "How do I receive my policy certificate?",
                  answer:
                    "Your policy certificate, carrying all the information of your policy will be issued to you monthly via your registered email after payment has been made.",
                },
                {
                  question: "How do I log a claim in the event of an incident?",
                  answer: (
                    <>
                      To log a claim,{" "}
                      <Link
                        href="https://app-web.allianz.com/AllianzEforms/Claims/motorClaim.html"
                        target="_blank"
                      >
                        visit this link
                      </Link>{" "}
                      to fill out the claims form. Our claims team will then
                      assist you through the process. Alternatively, you can
                      send your claims via email to info@allianz.ng. Our
                      dedicated customer experience team will promptly assist
                      you with your claim.
                    </>
                  ),
                },
                {
                  question:
                    "I can't find my car on your site. What should I do?",
                  answer: (
                    <>
                      Our site is continuously updated. If you can't find your
                      car, model, or year, please contact us{" "}
                      <Link
                        href="#"
                        onClick={() => dispatch(setOpenContactDialogOpen(true))}
                      >
                        here
                      </Link>
                      , and an agent will assist you with models not yet listed
                      on the site.
                    </>
                  ),
                },
              ]}
            />
          </Container>
        </Box>

        <Box sx={{ p: 2, backgroundColor: "white" }}>
          <Container>
            <Stack direction={"column"} sx={{ mt: 2 }}>
              <Box
                sx={{
                  textTransform: "uppercase",
                  fontSize: "0.8rem",
                  letterSpacing: "2px",
                  fontWeight: 300,
                  textAlign: "center",
                }}
              >
                Powered by
              </Box>
              <Stack
                direction={["row"]}
                sx={{ justifyContent: "center", alignItems: "center" }}
                columnGap={[2, 4]}
              >
                <Box>
                  <img
                    src={logocr}
                    style={{ height: "100%", maxHeight: "100px" }}
                    alt="Credit Registry"
                  />
                </Box>
                {/* <Box>
                  <img
                    src={logohellobugie}
                    style={{ height: "100%", maxHeight: "100px" }}
                    alt="Hello Budgie"
                  />
                </Box> */}
                <Box>
                  <img
                    src={logotransboxx}
                    style={{ height: "100%", maxHeight: "100px" }}
                    alt="Transboxx"
                  />
                </Box>

                {/* <Box>
                  <img
                    src={logops}
                    style={{ height: "100%", maxHeight: "100px" }}
                    alt="PayStack"
                  />
                </Box> */}
              </Stack>
            </Stack>
          </Container>
        </Box>

        {/* <Box
          sx={{
            position: "fixed",
            bottom: "14px",
            left: "14px",
          }}
        >
          <HelpButton />
        </Box> */}
      </Layout>
    </>
  );
}
