import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

//******************************************************************************
export default function ButtonLoading({
  variant = "contained",
  disabled,
  isLoading,
  children,
  onClick,
}) {
  return (
    <Button
      variant="contained"
      sx={{ minWidth: "150px", height: "40px" }}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={20} /> : children}
    </Button>
  );
}
