import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Components
import logo from "../../assets/logo-512-light.png";

import DialogTitleQuote from "./DialogTitleQuote";
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import Agent from "./Agent";
import AlertSnackBar from "../AlertSnackBar";

import StepName from "./StepName";
import StepWhatToInsure from "./StepWhatToInsure";

import StepProductHome from "./StepProductHome";
import StepProductDetails from "./StepProductDetails";

import StepCar from "./StepCar";
import StepCarValue from "./StepCarValue";
import StepCarHome from "./StepCarHome";

import StepCarDriver from "./StepCarDriver";
import StepCarWork from "./StepCarWork";
import StepCarRegistration from "./StepCarRegistration";
import StepCarChassis from "./StepCarChassis";
import StepCarModifications from "./StepCarModifications";
import StepCarInsurance from "./StepCarInsurance";
import StepCarInsuranceClaims from "./StepCarInsuranceClaims";
import StepCarInsuranceCancel from "./StepCarInsuranceCancel";
import StepCarComplete from "./StepCarComplete";

import StepThird from "./StepThird";
import StepThirdComplete from "./StepThirdComplete";

import StepUser from "./StepUser";
import StepUserContact from "./StepUserContact";
import StepUserContactVerify from "./StepUserContactVerify";

import StepUserContactEmail from "./StepUserContactEmail";
import StepUserContactEmailVerify from "./StepUserContactEmailVerify";
import StepUserContactPhone from "./StepUserContactPhone";
import StepUserContactPhoneVerify from "./StepUserContactPhoneVerify";

import StepUserId from "./StepUserId";
import StepUserIdNr from "./StepUserIdNr";
import StepUserIncome from "./StepUserIncome";
import StepUserOccupation from "./StepUserOccupation";
import StepUserBvn from "./StepUserBvn";
import StepUserComplete from "./StepUserComplete";

import StepCheckout from "./StepCheckout";
import StepPaymentConfirmation from "./StepPaymentConfirmation";
import StepPayment from "./StepPayment";
import StepFinish from "./StepFinish";
import StepRestart from "./StepRestart";

import HelpButton from "../HelpButton";

// State
import {
  STEP_INTRO,
  STEP_WHAT_TO_INSURE,
  STEP_PRODUCT_HOME,
  STEP_PRODUCT_DETAILS,
  STEP_CAR,
  STEP_CAR_VALUE,
  STEP_CAR_HOME,
  STEP_CAR_DRIVER,
  STEP_CAR_WORK,
  STEP_CAR_INSURANCE,
  STEP_CAR_INSURANCE_CLAIMS,
  STEP_CAR_INSURANCE_CANCEL,
  STEP_CAR_REGISTRATION,
  STEP_CAR_CHASSIS,
  STEP_CAR_MODIFICATIONS,
  STEP_CAR_COMPLETE,
  STEP_THIRD,
  STEP_THIRD_COMPLETE,
  STEP_USER,
  STEP_USER_CONTACT_EMAIL,
  STEP_USER_CONTACT_EMAIL_VERIFY,
  STEP_USER_CONTACT_PHONE,
  STEP_USER_CONTACT_PHONE_VERIFY,
  STEP_USER_CONTACT,
  STEP_USER_CONTACT_VERIFY,
  STEP_USER_ID,
  STEP_USER_ID_NR,
  STEP_USER_OCCUPATION,
  STEP_USER_BVN,
  STEP_USER_COMPLETE,
  STEP_USER_INCOME,
  STEP_CHECKOUT,
  STEP_PAYMENT,
  STEP_FINISH,
  MAX_STEPS,
  STEP_PAYMENT_CONFIRMATION,
} from "./steps";
import {
  setDialogOpen,
  previousStep,
  resetStatus,
  setDialogCheckOnOpen,
} from "../../app/features/quoteSlice";
import { clQuote } from "../../app/objects/clQuote";
import { STATUS_LOADING } from "../../app/status";
import { products } from "../../app/lists/products";

// Components
const ToolbarButton = ({
  label = "toolbar button",
  onClick,
  disabled,
  children,
}) => {
  return (
    <IconButton
      aria-label={label}
      onClick={onClick}
      sx={{
        opacity: "0.5",
        transition: "all 0.2s ease-in-out",
        ":hover": { opacity: 1 },
      }}
      //  size="small"
      disabled={disabled ? true : false}
    >
      {children}
    </IconButton>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.divider,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

//******************************************************************************
export default function DialogGetQuote() {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [isBusy, setIsBusy] = useState(false);
  const quote = useSelector((state) => state.quote);
  const item = useSelector((state) => state.quote.item);

  const [values, setValues] = useState(clQuote());

  const cur_product = products
    .filter((item) => item.product_type === quote.item.product_type)
    .map((item) => item);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
  };

  const nextStep = () => {
    switch (step) {
      case STEP_INTRO:
        setStep(STEP_WHAT_TO_INSURE);
        break;
      case STEP_WHAT_TO_INSURE:
        setStep(STEP_PRODUCT_HOME);
        break;
      case STEP_PRODUCT_HOME:
        setStep(STEP_CAR_DRIVER);
        break;
      case STEP_CAR_COMPLETE:
        setStep(STEP_USER);
        break;
      default:
        setStep(step + 1);
        break;
    }
  };

  if (
    quote.dialog_check_on_open &&
    quote.item.first_name &&
    quote.item.last_name &&
    quote.item.car_make &&
    quote.step != STEP_CHECKOUT
  ) {
    return (
      <Dialog
        onClose={(e, reason) => {
          if (reason !== "backdropClick") dispatch(setDialogOpen(false));
        }}
        open={quote.dialog_open ? true : false}
        fullWidth
        fullScreen={true}
      >
        <DialogTitleQuote
          disabled={isBusy || quote.status === STATUS_LOADING}
        />

        <DialogContent
          sx={{
            padding: 2,
            backgroundColor: "background.default",
          }}
          dividers
        >
          <Container maxWidth={"sm"} disableGutters>
            <DialogIntro
              messages={[
                <>Welcome back {quote.item.first_name}.</>,
                cur_product[0].name && (
                  <>
                    Would you like to continue with the{" "}
                    <b>{cur_product[0].name}</b> application for your{" "}
                    <b>{quote.item.car_make}?</b>
                  </>
                ),
              ]}
            />

            <DialogForm>
              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  sx={{ minWidth: "150px" }}
                  disabled={
                    !quote.item.first_name || !quote.item.last_name || isBusy
                  }
                  onClick={() => dispatch(setDialogCheckOnOpen(false))}
                >
                  Continue
                </Button>
              </Box>
            </DialogForm>
          </Container>
        </DialogContent>
      </Dialog>
    );
  }

  // Quote is paid and successfull
  if (quote.item.paid_status) {
    return (
      <Dialog
        onClose={(e, reason) => {
          if (reason !== "backdropClick") dispatch(setDialogOpen(false));
        }}
        open={quote.dialog_open ? true : false}
        fullWidth
        fullScreen={true}
      >
        <DialogTitleQuote
          disabled={isBusy || quote.status === STATUS_LOADING}
        />
        <DialogContent
          sx={{
            padding: 0,
            backgroundColor: "background.default",
          }}
          dividers
        >
          <Container maxWidth={"sm"} disableGutters>
            <StepRestart />
          </Container>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={(e, reason) => {
        if (reason !== "backdropClick") dispatch(setDialogOpen(false));
      }}
      open={quote.dialog_open}
      fullWidth
      fullScreen={true}
    >
      <DialogTitleQuote disabled={isBusy || quote.status === STATUS_LOADING} />

      <DialogContent
        sx={{
          padding: quote.step === STEP_CHECKOUT ? 0 : 2,
          backgroundColor: "background.default",
        }}
        dividers
      >
        {quote.step !== STEP_CHECKOUT && (
          <Container maxWidth={"sm"} disableGutters sx={{ mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={Math.round((quote.step * 100) / MAX_STEPS)}
                />
              </Box>
              <Box sx={{ color: "secondary.main", fontSize: "0.85rem" }}>
                {Math.round((quote.step * 100) / MAX_STEPS)}%
              </Box>
            </Box>
          </Container>
        )}

        <Container maxWidth={"sm"} disableGutters>
          {!item?.first_name ||
          !item?.last_name ||
          quote.step === STEP_INTRO ? (
            <StepName />
          ) : !item.product_type || quote.step === STEP_WHAT_TO_INSURE ? (
            <StepWhatToInsure />
          ) : (
            <>
              {quote.step === STEP_CAR && <StepCar />}
              {quote.step === STEP_CAR_VALUE && <StepCarValue />}
              {quote.step === STEP_CAR_HOME && <StepCarHome />}
              {quote.step === STEP_CAR_DRIVER && <StepCarDriver />}
              {quote.step === STEP_CAR_WORK && <StepCarWork />}
              {quote.step === STEP_CAR_INSURANCE && <StepCarInsurance />}
              {quote.step === STEP_CAR_INSURANCE_CLAIMS && (
                <StepCarInsuranceClaims />
              )}
              {quote.step === STEP_CAR_INSURANCE_CANCEL && (
                <StepCarInsuranceCancel />
              )}
              {quote.step === STEP_CAR_MODIFICATIONS && (
                <StepCarModifications />
              )}
              {quote.step === STEP_CAR_REGISTRATION && <StepCarRegistration />}
              {quote.step === STEP_CAR_CHASSIS && <StepCarChassis />}
              {quote.step === STEP_CAR_COMPLETE && <StepCarComplete />}

              {quote.step === STEP_USER && <StepUser />}

              {quote.step === STEP_USER_CONTACT_EMAIL && (
                <StepUserContactEmail />
              )}
              {quote.step === STEP_USER_CONTACT_EMAIL_VERIFY && (
                <StepUserContactEmailVerify />
              )}

              {quote.step === STEP_USER_CONTACT_PHONE && (
                <StepUserContactPhone />
              )}
              {quote.step === STEP_USER_CONTACT_PHONE_VERIFY && (
                <StepUserContactPhoneVerify />
              )}

              {quote.step === STEP_USER_CONTACT && <StepUserContact />}
              {quote.step === STEP_USER_CONTACT_VERIFY && (
                <StepUserContactVerify />
              )}
              {quote.step === STEP_USER_ID && <StepUserId />}
              {quote.step === STEP_USER_ID_NR && <StepUserIdNr />}
              {quote.step === STEP_USER_BVN && <StepUserBvn />}
              {quote.step === STEP_USER_INCOME && <StepUserIncome />}
              {quote.step === STEP_USER_OCCUPATION && <StepUserOccupation />}
              {quote.step === STEP_USER_COMPLETE && <StepUserComplete />}
            </>
          )}
          {/* 
          {quote.item.product_type === "car" && (
            <>
              {quote.step === STEP_CAR && <StepCar />}
              {quote.step === STEP_CAR_HOME && <StepCarHome />}
              {quote.step === STEP_CAR_DRIVER && <StepCarDriver />}
              {quote.step === STEP_CAR_WORK && <StepCarWork />}
              {quote.step === STEP_CAR_INSURANCE && <StepCarInsurance />}
              {quote.step === STEP_CAR_INSURANCE_CLAIMS && (
                <StepCarInsuranceClaims />
              )}
              {quote.step === STEP_CAR_INSURANCE_CANCEL && (
                <StepCarInsuranceCancel />
              )}
              {quote.step === STEP_CAR_MODIFICATIONS && (
                <StepCarModifications />
              )}
              {quote.step === STEP_CAR_REGISTRATION && <StepCarRegistration />}
              {quote.step === STEP_CAR_CHASSIS && <StepCarChassis />}
              {quote.step === STEP_CAR_COMPLETE && <StepCarInsurancePromo />}
            </>
          )}
           */}

          {quote.item.product_type === "third" && (
            <>
              {quote.step === STEP_THIRD && <StepThird />}
              {quote.step === STEP_THIRD_COMPLETE && <StepThirdComplete />}
            </>
          )}

          {quote.step === STEP_CHECKOUT && <StepCheckout />}
          {quote.step === STEP_PAYMENT_CONFIRMATION && (
            <StepPaymentConfirmation />
          )}
          {quote.step === STEP_PAYMENT && <StepPayment />}

          {quote.step === STEP_FINISH && <StepFinish />}

          {step === STEP_PRODUCT_HOME && (
            <StepProductHome
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              nextStep={nextStep}
            />
          )}

          {step === STEP_PRODUCT_DETAILS && (
            <StepProductDetails
              values={values}
              setValues={setValues}
              handleChange={handleChange}
              nextStep={nextStep}
            />
          )}
        </Container>
      </DialogContent>

      {/* <Box
        sx={{
          position: "fixed",
          bottom: "15px",
          p: 2,
          width: "100%",
        }}
      >
        <Container maxWidth={"sm"} disableGutters>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <BorderLinearProgress variant="determinate" value={20} />
            </Box>
            <Box sx={{ color: "secondary.main", fontSize: "0.85rem" }}>20%</Box>
          </Box>
        </Container>
      </Box> */}

      <Box
        sx={{
          position: "fixed",
          bottom: "14px",
          left: "14px",
        }}
      >
        <HelpButton
          caption={
            quote.step === STEP_CAR
              ? "Help, I can't find my car model."
              : "Help"
          }
        />
      </Box>
      {quote.step !== STEP_CHECKOUT && (
        <AlertSnackBar
          error={quote.error}
          onClose={() => dispatch(resetStatus())}
        />
      )}
    </Dialog>
  );
}
