import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
  validateEmail,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepUserContact() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    phone: "",
    phone2: "",
    email: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      phone: "",
      phone2: "",
      email: "",
    };

    if (quote.item.phone === "") tmpErr.phone = "Please enter your phone.";

    if (quote.item.email === "")
      tmpErr.email = "Please enter your email address.";
    else if (!validateEmail(quote.item.email))
      tmpErr.email = "Invalid email address.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            For optimal service, please provide your preferred contact
            information.
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <TextField
              id="phone"
              label="Phone"
              variant="outlined"
              value={quote.item.phone}
              fullWidth
              onChange={(e) =>
                dispatch(
                  updateProperty({
                    prop: "phone",
                    value: e.target.value,
                  })
                )
              }
              error={errors.phone !== ""}
              helperText={errors.phone}
              required
              disabled={isBusy || quote.status === STATUS_LOADING}
              inputProps={{ maxLength: 25 }}
            />
            {quote.item.phone && (
              <TextField
                id="phone2"
                label="Alternative Phone"
                variant="outlined"
                value={quote.item.phone2}
                fullWidth
                onChange={(e) =>
                  dispatch(
                    updateProperty({
                      prop: "phone2",
                      value: e.target.value,
                    })
                  )
                }
                error={errors.phone2 !== ""}
                helperText={errors.phone2}
                disabled={isBusy || quote.status === STATUS_LOADING}
                inputProps={{ maxLength: 25 }}
              />
            )}

            <TextField
              id="email"
              label="Email"
              variant="outlined"
              value={quote.item.email}
              fullWidth
              onChange={(e) =>
                dispatch(
                  updateProperty({
                    prop: "email",
                    value: e.target.value,
                  })
                )
              }
              error={errors.email !== ""}
              helperText={errors.email}
              disabled={isBusy || quote.status === STATUS_LOADING}
              required
              inputProps={{ maxLength: 255 }}
            />
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={
                  !quote.item.phone || isBusy || quote.status === STATUS_LOADING
                }
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
