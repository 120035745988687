export const doc_types = [
  { label: "National ID (NIN)", value: "National ID", number: "ID Number" },
  {
    label: "Driver's License (NDL)",
    value: "Driver's License",
    number: "Driver's License",
  },
  {
    label: "Voter’s Identification Number (VIN)",
    value: "Voter's ID",
    number: "Voter's Number",
  },
  {
    label: "International Passport",
    value: "International Passport",
    number: "International Passport",
  },
];
