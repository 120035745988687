import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Icons
import HomeIcon from "@mui/icons-material/Home";

// Components
import Layout from "../components/auth/Layout";

//******************************************************************************
export default function NotFound() {
  return (
    <Layout title={"Page Not Found"}>
      <Box sx={{ textAlign: "center" }}>
        <Typography gutterBottom>
          Oops! The page you are looking for could not be found.
        </Typography>
        <Typography gutterBottom>Let us help you get back on track.</Typography>
        <Button
          variant="contained"
          component={NavLink}
          to={"/"}
          startIcon={<HomeIcon />}
          sx={{ my: 2 }}
        >
          Go Home
        </Button>
      </Box>
    </Layout>
  );
}
