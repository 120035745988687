import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCarModifications() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    dispatch(updateProperty({ prop: "car_modified", value: value }));

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Has your <b>{quote.item.car_make}</b> ever had any
            performance-enhancing modifications?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"row"} sx={{ mb: 2 }}>
            <Button
              variant={
                quote.item.car_modified === "no" ? "contained" : "outlined"
              }
              startIcon={
                quote.item.car_modified === "no" ? <CheckIcon /> : null
              }
              onClick={() => handleNextStep("no")}
              fullWidth
              disabled={isBusy}
            >
              No
            </Button>
            <Button
              variant={
                quote.item.car_modified === "yes" ? "contained" : "outlined"
              }
              startIcon={
                quote.item.car_modified === "yes" ? <CheckIcon /> : null
              }
              onClick={() => handleNextStep("yes")}
              fullWidth
              disabled={isBusy}
            >
              Yes
            </Button>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
