import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { clQuote } from "../objects/clQuote";
import jdpApi from "../jdpApi";
import { handleAxiosError } from "../../functions/errorWithMessage";

import {
  STEP_INTRO,
  STEP_WHAT_TO_INSURE,
  STEP_CAR,
  STEP_CAR_COMPLETE,
  STEP_THIRD,
  STEP_THIRD_COMPLETE,
  STEP_USER,
  STEP_USER_COMPLETE,
  STEP_USER_CONTACT_PHONE,
  STEP_USER_CONTACT_EMAIL,
  STEP_CHECKOUT,
} from "../../components/quote/steps";

import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCES,
  STATUS_FAILED,
} from "../status";

const API_ROOT = "/quote";

// Stored in cookie
const defaultCookie = {
  quote: clQuote(),
  step: STEP_INTRO,
  dialog_open: false,
};

const saveCookie = (value) => {
  sessionStorage.setItem(process.env.REACT_APP_QUOTE, JSON.stringify(value));
};

// Initialize
export const initializeQuote = createAsyncThunk(
  "quote/initializeQuote",
  async (_, thunkAPI) => {
    const output = defaultCookie;
    try {
      // Check for Quote
      let storedQuote;
      if (!thunkAPI.getState().quote.item.first_name) {
        storedQuote = await localStorage.getItem(process.env.REACT_APP_QUOTE);

        if (!storedQuote) {
          storedQuote = await sessionStorage.getItem(
            process.env.REACT_APP_QUOTE
          );
        }

        // Check if a valid quote is available
        if (storedQuote && storedQuote !== "undefined") {
          if (process.env.NODE_ENV === "development")
            console.log("initializeQuote(): Loading stored quote");
          let storedData = JSON.parse(storedQuote);

          // Items in Cookie
          output.quote = clQuote(storedData.quote);
          output.step = parseInt(storedData.step);
          output.dialog_open = storedData.dialog_open ? true : false;

          return output;
        } else {
          if (process.env.NODE_ENV === "development")
            console.log("initializeQuote(): No stored quote available");
          return output;
        }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Update Quote on the server
export const nextStep = createAsyncThunk(
  "quote/nextStep",
  async (_, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;
      const step = thunkAPI.getState().quote.step;
      //console.log("NEXT STEP", quote);
      //process.env.REACT_APP_COMPANY_ID
      let response;
      if (quote.uuid) {
        response = await jdpApi.patch(`${API_ROOT}/${quote.uuid}`, {
          quote,
          step,
        });
      } else {
        response = await jdpApi.post(`${API_ROOT}`, {
          quote,
          step,
        });
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Update Quote on the server without next step
export const updateQuote = createAsyncThunk(
  "quote/updateQuote",
  async (_, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;
      const step = thunkAPI.getState().quote.step;

      const response = await jdpApi.patch(`${API_ROOT}/${quote.uuid}`, {
        quote,
        step,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Checkout
export const checkout = createAsyncThunk(
  "quote/checkout",
  async (_, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;
      const step = thunkAPI.getState().quote.step;

      const response = await jdpApi.patch(
        `${API_ROOT}/${quote.uuid}/checkout`,
        { quote, step }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Payment
export const payment = createAsyncThunk(
  "quote/payment",
  async (_, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;

      const response = await jdpApi.patch(`${API_ROOT}/${quote.uuid}/payment`, {
        amount: quote.amount,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Verify Order
export const verifyOrder = createAsyncThunk(
  "quote/verifyOrder",
  async (_, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;

      const response = await jdpApi.get(`${API_ROOT}/${quote.uuid}/verify`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Validate Email
export const verifyEmail = createAsyncThunk(
  "quote/verifyEmail",
  async ({ email, id_check, quote_id }, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;

      const response = await jdpApi.patch(`${API_ROOT}/${quote_id}/email`, {
        email,
        id_check,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Resend Validation Email
export const resendVerificationEmail = createAsyncThunk(
  "quote/resendVerificationEmail",
  async (_, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;

      const response = await jdpApi.post(`${API_ROOT}/${quote.uuid}/email`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Verify OTP
export const verifyOTP = createAsyncThunk(
  "quote/verifyOTP",
  async ({ otp }, thunkAPI) => {
    try {
      const quote = thunkAPI.getState().quote.item;

      const response = await jdpApi.post(`${API_ROOT}/${quote.uuid}/phone`, {
        otp,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Structure of initial state
const initialState = {
  item: clQuote(),
  form_data: clQuote(),

  step: STEP_INTRO,
  dialog_open: false,
  dialog_check_on_open: true,
  status: STATUS_IDLE,
  checkout_status: STATUS_IDLE,
  error: "",
};

//********************************************************************************
export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  // Actions
  reducers: {
    resetStatus: (state, action) => {
      state.status = STATUS_IDLE;
      state.error = "";
    },
    setDialogCheckOnOpen: (state, action) => {
      state.dialog_check_on_open = action.payload;
    },
    resetQuote: (state, action) => {
      state.item = clQuote();
      state.step = STEP_INTRO;
      state.dialog_open = false;
      state.status = STATUS_IDLE;
      state.error = "";
    },
    setCheckoutStatus: (state, action) => {
      state.checkout_status = action.payload;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    // nextStep: (state, action) => {
    //   switch (state.step) {
    //     case STEP_WHAT_TO_INSURE:
    //       state.step = STEP_CAR;
    //       break;
    //     //if (state.item.product_type === "car") state.step = STEP_CAR;
    //     // else if (state.item.product_type === "third") state.step = STEP_THIRD;
    //     // else state.step = STEP_WHAT_TO_INSURE;
    //     //break;
    //     // case STEP_CAR_COMPLETE:
    //     // case STEP_THIRD_COMPLETE:
    //     //   state.step = STEP_USER;
    //     //   break;
    //     // case STEP_USER_COMPLETE:
    //     //   state.step = STEP_CHECKOUT;
    //     //   break;
    //     default:
    //       state.step = state.step + 1;
    //       //console.log("Next step", state.step + 1);
    //       break;
    //   }
    // },
    previousStep: (state, action) => {
      if (state.step > 0) {
        if (
          (state.step === STEP_CAR && state.item.email_confirmed) ||
          state.step === STEP_USER_CONTACT_PHONE
        )
          state.step = state.step - 2;
        else state.step = state.step - 1;
        // switch (state.step) {
        //   case STEP_CAR:
        //     state.step = STEP_INTRO;
        //     break;
        //   case STEP_USER:
        //     if (state.item.product_type === "car")
        //       state.step = STEP_CAR_COMPLETE;
        //     else if (state.item.product_type === "third")
        //       state.step = STEP_THIRD_COMPLETE;
        //     else state.step = STEP_WHAT_TO_INSURE;
        //     break;
        //   case STEP_CHECKOUT:
        //     state.step = STEP_USER_COMPLETE;
        //     break;
        //   default:
        //     state.step = state.step - 1;
        //     break;
        // }
      } else state.dialog_open = false;
    },
    updateProperty: (state, action) => {
      state.dialog_check_on_open = false;
      if (state.item.hasOwnProperty(action.payload.prop)) {
        state.item[action.payload.prop] = action.payload.value;

        const newCookie = defaultCookie;
        newCookie.quote = state.item;
        newCookie.step = state.step;
        newCookie.dialog_open = state.dialog_open;

        saveCookie(newCookie);

        // if (state.item.first_name && state.item.last_name) {
        //   if (action.payload.prop === "product_type") {
        //     switch (action.payload.value) {
        //       case "car":
        //         state.step = STEP_CAR;
        //         break;
        //       case "third":
        //         state.step = STEP_THIRD;
        //         break;
        //       default:
        //         state.step = STEP_WHAT_TO_INSURE;
        //         break;
        //     }
        //   }
        // }

        // sessionStorage.setItem(
        //   process.env.REACT_APP_QUOTE,
        //   JSON.stringify(newCookie)
        // );
      }
    },
    setFormData: (state, action) => {
      if (action.payload) state.form_data = clQuote(action.payload);
      state.error = "";
    },
    setDialogOpen: (state, action) => {
      if (!action.payload) state.dialog_check_on_open = true;

      state.dialog_open = action.payload;
      if (state.step > STEP_CHECKOUT) state.step = STEP_CHECKOUT;

      const newCookie = defaultCookie;
      newCookie.quote = state.item;
      newCookie.step = state.step;
      newCookie.dialog_open = state.dialog_open;
      saveCookie(newCookie);

      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Initialize quote via stored token
      .addCase(initializeQuote.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(initializeQuote.fulfilled, (state, action) => {
        if (action.payload.quote) {
          state.item = clQuote(action.payload.quote);
          state.step = action.payload.step;
          state.dialog_open = action.payload.dialog_open;
        } else {
          state.item = clQuote();
          state.step = STEP_INTRO;
          state.dialog_open = false;
        }

        state.status = STATUS_SUCCES;
      })
      .addCase(initializeQuote.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Next step
      .addCase(nextStep.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(nextStep.fulfilled, (state, action) => {
        state.dialog_check_on_open = false;
        state.item = clQuote(action.payload.quote);

        if (
          (state.step === STEP_USER_CONTACT_PHONE ||
            state.step === STEP_USER_CONTACT_EMAIL) &&
          state.item.email_confirmed
        )
          state.step = state.step + 2;
        else state.step = state.step + 1;

        const newCookie = defaultCookie;
        newCookie.quote = state.item;
        newCookie.step = state.step;
        newCookie.dialog_open = state.dialog_open;
        saveCookie(newCookie);

        state.status = STATUS_SUCCES;
      })
      .addCase(nextStep.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Update quote
      .addCase(updateQuote.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(updateQuote.fulfilled, (state, action) => {
        state.item = clQuote(action.payload.quote);

        const newCookie = defaultCookie;
        newCookie.quote = state.item;
        newCookie.step = state.step;
        newCookie.dialog_open = state.dialog_open;
        saveCookie(newCookie);

        state.status = STATUS_SUCCES;
      })
      .addCase(updateQuote.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Checkout
      .addCase(checkout.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(checkout.fulfilled, (state, action) => {
        state.item = clQuote(action.payload.quote);
        //state.step = state.step + 1;
        state.status = STATUS_SUCCES;
      })
      .addCase(checkout.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Payment
      .addCase(payment.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(payment.fulfilled, (state, action) => {
        state.item = clQuote(action.payload.quote);
        //state.step = state.step + 1;
        state.status = STATUS_SUCCES;
      })
      .addCase(payment.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Verify Order
      .addCase(verifyOrder.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(verifyOrder.fulfilled, (state, action) => {
        state.item = action.payload.quote;
        //state.step = state.step + 1;
        state.status = STATUS_SUCCES;
      })
      .addCase(verifyOrder.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Verify Email
      .addCase(verifyEmail.pending, (state) => {
        //state.item = clQuote();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.item = clQuote(action.payload.quote);
        state.step = action.payload.quote.step;

        const newCookie = defaultCookie;
        newCookie.quote = state.item;
        newCookie.step = state.step;
        newCookie.dialog_open = state.dialog_open;
        saveCookie(newCookie);

        state.status = STATUS_SUCCES;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Resend Verification Email
      .addCase(resendVerificationEmail.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(resendVerificationEmail.fulfilled, (state, action) => {
        state.item = clQuote(action.payload.quote);
        state.status = STATUS_SUCCES;
      })
      .addCase(resendVerificationEmail.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Verify OTP
      .addCase(verifyOTP.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.item = clQuote(action.payload.quote);
        state.status = STATUS_SUCCES;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      });
  },
});

export const {
  resetStatus,
  setCheckoutStatus,
  setDialogCheckOnOpen,
  setDialogOpen,
  setStep,
  previousStep,
  updateProperty,
  resetQuote,
} = quoteSlice.actions;

export default quoteSlice.reducer;
