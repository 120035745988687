import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCarInsurance() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    dispatch(updateProperty({ prop: "car_insurance_years", value: value }));

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            How many consecutive years have you maintained uninterrupted car
            insurance coverage for your vehicle?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"column"} sx={{ mb: 2 }}>
            <Button
              variant={
                quote.item.car_insurance_years === 0 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.car_insurance_years === 0 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(0)}
              fullWidth
              disabled={isBusy}
            >
              Never
            </Button>
            <Button
              variant={
                quote.item.car_insurance_years === 1 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.car_insurance_years === 1 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(1)}
              fullWidth
              disabled={isBusy}
            >
              At least 1 year
            </Button>
            <Button
              variant={
                quote.item.car_insurance_years === 2 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.car_insurance_years === 2 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(2)}
              fullWidth
              disabled={isBusy}
            >
              At least 2 years
            </Button>
            <Button
              variant={
                quote.item.car_insurance_years === 3 ? "contained" : "outlined"
              }
              startIcon={
                quote.item.car_insurance_years === 3 ? <CheckIcon /> : null
              }
              sx={{ minWidth: "150px" }}
              onClick={() => handleNextStep(3)}
              fullWidth
              disabled={isBusy}
            >
              Over +3 years
            </Button>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
