import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

// Icons
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

// Components
import FormContact from "../contact/FormContact";

// State
import { STATUS_LOADING } from "../../app/status";
import { resetStatus } from "../../app/features/authSlice";

//******************************************************************************
export default function Footer({
  appTitle = process.env.REACT_APP_NAME,
  title,
  children,
  options,
  isLoading,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const footerTitleStyle = {
    fontWeight: "300",
    fontSize: "1.5rem",
    textAlign: ["center", "left"],
  };

  return (
    <>
      <Container sx={{ my: 5, padding: 4 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Typography sx={footerTitleStyle}>Contact Us</Typography>
            <Box sx={{ py: 3 }}>
              <FormContact />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={footerTitleStyle}>Contact Information</Typography>
            <Stack direction={"row"} sx={{ py: 3 }}>
              <Stack gap={2} sx={{ flex: 1 }}>
                <Stack gap={3} direction={"row"} sx={{ alignItems: "center" }}>
                  <LocalPhoneIcon color={"primary"} fontSize="1.1rem" />
                  <Link href="tel:0800 255 4269" underline="hover">
                    0800 255 4269
                  </Link>
                </Stack>
                <Stack gap={3} direction={"row"} sx={{ alignItems: "center" }}>
                  <EmailIcon color={"primary"} fontSize="1.1rem" />
                  <Link href="mailto:info@allianz.ng" underline="hover">
                    info@allianz.ng{" "}
                  </Link>
                </Stack>

                <Stack gap={3} direction={"row"} sx={{ alignItems: "center" }}>
                  <DriveFileRenameOutlineIcon
                    color={"primary"}
                    fontSize="1.1rem"
                  />
                  <Link
                    href="https://app-web.allianz.com/AllianzEforms/Claims/motorClaim.html"
                    underline="hover"
                  >
                    Log a claim online
                  </Link>
                </Stack>
                <Stack
                  gap={3}
                  direction={"row"}
                  sx={{ alignItems: "center", mt: 2 }}
                >
                  <PlaceIcon fontSize="1.1rem" />
                  <Box>95 Broad Street, Lagos</Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
      <Box sx={{ backgroundColor: "#a8a7a7", p: 3, pb: 4 }}>
        <Stack
          sx={{
            gap: 5,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.0rem",
            my: 1,
            mb: 1,
          }}
        >
          <Link
            component={NavLink}
            to="/terms"
            underline="hover"
            color="white"
            sx={{ opacity: "0.8", "&:hover": { opacity: 1 } }}
          >
            Terms of Use
          </Link>
          <Link
            component={NavLink}
            to="/privacy"
            underline="hover"
            color="white"
            sx={{ opacity: "0.8", "&:hover": { opacity: 1 } }}
          >
            Privacy Policy
          </Link>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: ["column", "row"],
            fontSize: "0.85em",
            color: "#fff",
          }}
        >
          <Box>
            &copy;{new Date().getFullYear()}, Allianz Nigeria in collaboration
            with CreditRegistry.{" "}
          </Box>
          <Box> All rights reserved.</Box>
        </Box>
      </Box>
    </>
  );

  return (
    <Box component={"footer"} sx={{ my: 3 }}>
      <Stack
        sx={{
          gap: 3,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "0.9rem",
          opacity: "0.6",
          my: 2,
        }}
      >
        <Link component={NavLink} to="/terms" underline="hover" color="inherit">
          Terms of Use
        </Link>
        <Link
          component={NavLink}
          to="/privacy"
          underline="hover"
          color="inherit"
        >
          Privacy Policy
        </Link>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: ["column", "row"],
          opacity: 0.5,
          fontSize: "0.75em",
        }}
      >
        <Box>
          &copy;{new Date().getFullYear()}, {process.env.REACT_APP_NAME}.
        </Box>
        <Box> All rights reserved.</Box>
      </Box>
    </Box>
  );
}
