import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Icons
import carwhite from "../../assets/car.svg";
import congratulations from "../../assets/congratulations.svg";

import accident from "../../assets/allianz_accident.svg";
import thirdparty from "../../assets/allianz_third_party.svg";
import theft from "../../assets/allianz_theft.svg";
import assistance from "../../assets/allianz_assistance.svg";
import illegal from "../../assets/allianz_illegal.svg";
import business from "../../assets/allianz_bussiness.svg";
import maintenance from "../../assets/allianz_main.svg";
import damage from "../../assets/allianz_condition.svg";
import nature from "../../assets/allianz_nature.svg";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepPayment() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  let currency = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  });

  let payments = {
    value: 0,
    premium: 0,
    vat: 0,
    kyc: 290,
    annual: 0,
    markup: 0,
    monthly: 0,
  };

  if (quote.item.product_type === "car") {
    payments.value = 5000000;
    payments.premium = Math.round((payments.value * 5) / 100) + payments.kyc;
    payments.markup = Math.round(payments.premium * 1.3 * 100) / 100;
  } else {
    payments.premium = 15000;
    payments.markup = payments.premium;
  }

  payments.vat = Math.round(payments.markup * 0.075 * 100) / 100;
  payments.annual = Math.round((payments.markup + payments.vat) * 100) / 100;
  payments.monthly = Math.round((payments.annual / 12) * 100) / 100;

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  const cover = [
    { label: "Accident", image: accident, included: true },
    { label: "Third Party", image: thirdparty, included: true },
    { label: "Theft", image: theft, included: true },
    { label: "Roadside Assistance", image: maintenance, included: true },
    { label: "Mother Nature", image: nature, included: true },
    { label: "Illegal Use", image: illegal, included: false },
    { label: "Business Use", image: business, included: false },
    { label: "Maintenance", image: maintenance, included: false },
    { label: "Pre-existing Damage", image: damage, included: false },
  ];

  return (
    <>
      <DialogIntro
        messages={[
          <>
            {quote.item.product_type === "car"
              ? "Car value: " + currency.format(payments.value)
              : "Third Party Insurance"}
          </>,
          "Premium: " + currency.format(payments.premium),
          "VAT: " + currency.format(payments.vat),
          "KYC Fee: " + currency.format(payments.kyc),
          "Total Annual Premium: " + currency.format(payments.annual),
          quote.item.product_type === "car"
            ? "Monthly Premium: " + currency.format(payments.monthly)
            : "Once off payment",
          quote.item.product_type === "car"
            ? "Payment: " + currency.format(payments.monthly * 2)
            : "Payment: " + currency.format(payments.annual),
        ]}
      />
    </>
  );
}
