import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  isNGId,
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserBvn() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    bvn: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      bvn: "",
    };

    if (quote.item.bvn === "") tmpErr.bvn = "Please enter your BVN.";
    else if (!isNGId(quote.item.bvn)) tmpErr.bvn = "Please enter a valid BVN.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[<>What is your Bank Verification Number (BVN)?</>]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <TextField
              id="bvn"
              label="Bank Verification Number"
              variant="outlined"
              value={quote.item.bvn}
              fullWidth
              onChange={(e) =>
                dispatch(
                  updateProperty({
                    prop: "bvn",
                    value: e.target.value,
                  })
                )
              }
              error={errors.bvn !== ""}
              helperText={errors.bvn}
              required
              disabled={isBusy}
              inputProps={{ maxLength: 50 }}
            />

            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.bvn || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
