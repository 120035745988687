import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

// Components
import logo from "../../assets/logo-light.png";
import LayoutMain from "../layout/Layout";
import PageContent from "./PageContent";
import AlertSnackBar from "../AlertSnackBar";

// State
import { STATUS_LOADING } from "../../app/status";
import { resetStatus } from "../../app/features/authSlice";

//******************************************************************************
export default function Layout({
  appTitle = process.env.REACT_APP_NAME,
  title,
  children,
  options,
  isLoading,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <LayoutMain appTitle={appTitle} title={title}>
      {(isLoading || auth.status.status === STATUS_LOADING) && (
        <LinearProgress
          sx={{ position: "absolute", top: 0, height: "5px", width: "100%" }}
        />
      )}

      <Box
        sx={{
          displayPrint: "none",
          p: [0, 2, 3, 4],
        }}
      >
        <Container maxWidth="sm" disableGutters>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
            }}
          >
            <Link component={NavLink} to={"/"}>
              <img
                src={logo}
                alt={"logo"}
                style={{ width: "100%", maxWidth: "96px" }}
              />
            </Link>
          </Box>
          <Box>
            <PageContent title={title} options={options}>
              {children}
            </PageContent>
          </Box>

          {/* Footer */}
          <Box component={"footer"} sx={{ my: 3 }}>
            <Stack
              sx={{
                gap: 3,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "0.9rem",
                opacity: "0.6",
                my: 2,
              }}
            >
              <Link
                component={NavLink}
                to="/terms"
                underline="hover"
                color="inherit"
              >
                Terms of Use
              </Link>
              <Link
                component={NavLink}
                to="/privacy"
                underline="hover"
                color="inherit"
              >
                Privacy Policy
              </Link>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: ["column", "row"],
                opacity: 0.5,
                fontSize: "0.75em",
              }}
            >
              <Box>
                &copy;{new Date().getFullYear()}, {process.env.REACT_APP_NAME}.
              </Box>
              <Box> All rights reserved.</Box>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* <AlertSnackBar
        error={auth.error}
        onClose={() => dispatch(resetStatus())}
      /> */}
    </LayoutMain>
  );
}
