import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

// Componenets
import HelpIcon from "@mui/icons-material/Help";

// State
import { products } from "../../app/lists/products";
import jdpAuthApi from "../../app/jdpApi";
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";

import { updateProperty, nextStep } from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function ButtonOption({ children, onClick, disabled }) {
  return (
    <Alert
      icon={<HelpIcon fontSize="inherit" sx={{ color: "#666" }} />}
      variant="outlined"
      sx={{
        borderColor: "#aaa",
        color: "#333",
        cursor: "pointer",
        ":hover": { borderColor: "#111" },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Alert>
  );

  return (
    <Box>
      <Button
        variant="outlined"
        sx={{
          borderRadius: "5px",
          p: "10px",
          ":hover": { borderWidth: "2px" },
        }}
        color="inherit"
        onClick={onClick}
        disabled={disabled}
        startIcon={<HelpIcon />}
      >
        {children}
      </Button>
    </Box>
  );
}
