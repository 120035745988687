let i = 0;
export const STEP_INTRO = i++;
export const STEP_USER_CONTACT_EMAIL = i++;
export const STEP_USER_CONTACT_EMAIL_VERIFY = i++;
export const STEP_USER_CONTACT_PHONE = i++;
export const STEP_USER_CONTACT_PHONE_VERIFY = i++;

export const STEP_CAR = i++;
export const STEP_CAR_VALUE = i++;
export const STEP_CAR_HOME = i++;
export const STEP_CAR_REGISTRATION = i++;
export const STEP_CAR_CHASSIS = i++;
export const STEP_CAR_COMPLETE = i++;

export const STEP_USER = i++;
export const STEP_USER_ID = i++;
export const STEP_USER_ID_NR = i++;
export const STEP_USER_BVN = i++;
export const STEP_USER_INCOME = i++;
export const STEP_USER_OCCUPATION = i++;
export const STEP_USER_COMPLETE = i++;

export const STEP_CHECKOUT = i++;
export const STEP_PAYMENT_CONFIRMATION = i++;
export const STEP_FINISH = i++;
export const STEP_PAYMENT = i++;

export const STEP_WHAT_TO_INSURE = i++;
export const STEP_PRODUCT_HOME = i++;
export const STEP_PRODUCT_DETAILS = i++;

export const STEP_THIRD = i++;
export const STEP_THIRD_COMPLETE = i++;

export const STEP_USER_CONTACT = i++;
export const STEP_USER_CONTACT_VERIFY = i++;

export const MAX_STEPS = STEP_PAYMENT_CONFIRMATION;

export const STEP_CAR_DRIVER = i++;
export const STEP_CAR_WORK = i++;
export const STEP_CAR_INSURANCE = i++;
export const STEP_CAR_INSURANCE_CLAIMS = i++;
export const STEP_CAR_INSURANCE_CANCEL = i++;
export const STEP_CAR_MODIFICATIONS = i++;
