import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import ButtonLoading from "../ButtonLoading";

// State
import {
  validateFormErrors,
  animateForm,
  validateEmail,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepUserContactPhone() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    phone: "",
    phone2: "",
    email: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      phone: "",
      phone2: "",
      email: "",
    };

    if (quote.item.phone === "") tmpErr.phone = "Please enter your phone.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro messages={[<>Please provide your mobile phone number.</>]} />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <TextField
              id="phone"
              label="Mobile"
              variant="outlined"
              value={quote.item.phone}
              fullWidth
              onChange={(e) =>
                dispatch(
                  updateProperty({
                    prop: "phone",
                    value: e.target.value,
                  })
                )
              }
              error={errors.phone !== ""}
              helperText={errors.phone}
              required
              disabled={
                quote.item.phone_confirmed ||
                isBusy ||
                quote.status === STATUS_LOADING
              }
              inputProps={{ maxLength: 25 }}
            />
            {quote.item.phone && (
              <TextField
                id="phone2"
                label="Alternative Phone"
                variant="outlined"
                value={quote.item.phone2}
                fullWidth
                onChange={(e) =>
                  dispatch(
                    updateProperty({
                      prop: "phone2",
                      value: e.target.value,
                    })
                  )
                }
                error={errors.phone2 !== ""}
                helperText={errors.phone2}
                disabled={
                  quote.item.phone2_confirmed ||
                  isBusy ||
                  quote.status === STATUS_LOADING
                }
                inputProps={{ maxLength: 25 }}
              />
            )}

            <Box sx={{ textAlign: "right" }}>
              <ButtonLoading
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.phone}
                isLoading={isBusy || quote.status === STATUS_LOADING}
                onClick={handleNextStep}
              >
                Next
              </ButtonLoading>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
