import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

// Components
import Layout from "../../components/layout/LayoutLegal";

// State
import { STATUS_IDLE, STATUS_LOADING } from "../../app/status";
import { getLegal } from "../../app/features/themeSlice";

//******************************************************************************
export default function Terms() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  // Initialize - Application Settings
  // useEffect(() => {
  //   // Only get legal when we don't have it
  //   if (theme.legal.terms === STATUS_IDLE) {
  //     // Load Legal
  //     dispatch(getLegal({ page: "terms" }))
  //       .then(unwrapResult)
  //       .then((originalPromiseResult) => {})
  //       .catch((rejectedValueOrSerializedError) => {});
  //   }
  // }, [theme.legal.terms]);

  const liClass = {
    marginBottom: "20px",
  };

  return (
    <Layout
      title="Terms &amp; Conditions"
      isLoading={theme.status === STATUS_LOADING}
    >
      <h2>IMPORTANT INFORMATION:</h2>
      <ol>
        <li style={liClass}>
          An individual who assists an applicant to complete this proposal form
          for insurance shall be deemed to have done so as the agent of the
          applicant in accordance with section 54(2), Insurance Act, 2003
        </li>
        <li style={liClass}>
          The liability of the company does not commence until this application
          is accepted and the premium is paid in accordance with section 50(1)
          of Insurance Act 2003.
        </li>
        <li style={liClass}>
          To ensure full activation of your new comprehensive motor insurance
          coverage, an in-person inspection is required within 30 days of policy
          issuance to avoid cancellation. During this inspection, you'll need to
          provide photos of your vehicle from various angles, a personal selfie,
          vehicle registration and licensing documents, and personal
          identification such as a National ID, passport, or driver's license.
          An agent may contact you to schedule your inspection, or you can
          proactively reach out to an Allianz agent using your policy number.
          Coverage for claims is limited to only 10% of full vehicle value until
          inspection is completed.
        </li>
        <li style={liClass}>
          ALL PAYMENTS SHOULD BE MADE TO DESIGNATED ALLIANZ BANK ACCOUNTS ONLY.
        </li>
      </ol>
      {/* {theme.status === STATUS_LOADING || theme.legal.terms === STATUS_IDLE ? (
        <Stack>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width={"30%"} sx={{ mb: 2 }} />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width={"20%"} />
        </Stack>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: theme.legal.terms }} />
      )} */}
    </Layout>
  );
}
