import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// Icons
import CheckIcon from "@mui/icons-material/Check";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import { doc_types } from "../../app/lists/doc_types";
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserId() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({});

  const handleNextStep = (value) => {
    setIsBusy(true);
    const tmpErr = {};

    dispatch(updateProperty({ prop: "id_document", value }));

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  // const doc_types = [
  //   { label: "National ID (NIN)", value: "National ID" },
  //   { label: "Driver's License (NDL)", value: "Driver's License" },
  //   { label: "Voter’s Identification Number (VIN)", value: "Voter's ID" },
  //   { label: "International Passport", value: "International ID" },
  // ];

  return (
    <>
      <DialogIntro
        messages={[<>What is your preferred method of identification?</>]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2} direction={"column"} sx={{ mb: 2 }}>
            {doc_types.map((doc, index) => (
              <Button
                key={index}
                variant={
                  quote.item.id_document === doc.value
                    ? "contained"
                    : "outlined"
                }
                startIcon={
                  quote.item.id_document === doc.value ? <CheckIcon /> : null
                }
                sx={{ minWidth: "150px" }}
                onClick={() => handleNextStep(doc.value)}
                fullWidth
              >
                {doc.label}
              </Button>
            ))}
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
