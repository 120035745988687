import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

// Components
import MainButtonHome from "./ButtonHome";

// State
import { setDialogOpen } from "../../app/features/quoteSlice";

import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function ButtonQuote({ disabled }) {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);

  return (
    <>
      <Button
        variant="contained"
        sx={{ mr: 1 }}
        onClick={() => dispatch(setDialogOpen(true))}
        disabled={quote.status === STATUS_LOADING}
      >
        {quote.item.paid_status
          ? "Insurance"
          : quote.item.uuid
          ? "Continue"
          : "Help"}
      </Button>
    </>
  );
}
