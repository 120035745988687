import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";

import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepThird() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    address1: "",
    address2: "",
    city: "",
    zipcode: "",
    state: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      address1: "",
      address2: "",
      city: "",
      zipcode: "",
      state: "",
    };

    if (quote.item.address1 === "")
      tmpErr.address1 = "Please enter your address.";
    if (quote.item.zipcode === "")
      tmpErr.zipcode = "Please enter your zipcode.";
    if (quote.item.city === "") tmpErr.city = "Please enter your city.";
    if (quote.item.state === "") tmpErr.state = "Please enter your state.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  const setState = (newValue) => {
    dispatch(updateProperty({ prop: "state", value: newValue }));
  };
  const [stateInput, setStateInput] = useState("");
  const setStateInputValue = (newValue) => {
    setStateInput(newValue);
  };

  const states = [
    "Abia State",
    "Adamawa State",
    "Akwa Ibom State",
    "Anambra State",
    "Bauchi State",
    "Bayelsa State",
    "Benue State",
    "Borno State",
    "Cross River State",
    "Delta State",
    "Ebonyi State",
    "Edo State",
    "Ekiti State",
    "Enugu State",
    "Federal Capital Territory",
    "Gombe State",
    "Imo State",
    "Jigawa State",
    "Kaduna State",
    "Kano State",
    "Katsina State",
    "Kebbi State",
    "Kogi State",
    "Kwara State",
    "Lagos",
    "Nasarawa State",
    "Niger State",
    "Ogun State",
    "Ondo State",
    "Osun State",
    "Oyo State",
    "Plateau State",
    "Sokoto State",
    "Taraba State",
    "Yobe State",
    "Zamfara State",
  ];

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Hi <b>{quote.item.first_name}</b>
          </>,
          <>Where do you live?</>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Stack gap={2} direction={["column"]}>
              <TextField
                id="address1"
                label="Address"
                variant="outlined"
                value={quote.item.address1}
                fullWidth
                onChange={(e) =>
                  dispatch(
                    updateProperty({ prop: "address1", value: e.target.value })
                  )
                }
                error={errors.address1 !== ""}
                helperText={errors.address1}
                required
                disabled={isBusy}
              />
              <TextField
                id="address2"
                label="Address"
                variant="outlined"
                value={quote.item.address2}
                fullWidth
                onChange={(e) =>
                  dispatch(
                    updateProperty({ prop: "address2", value: e.target.value })
                  )
                }
                error={errors.address2 !== ""}
                helperText={errors.address2}
                disabled={isBusy}
              />
              <Stack gap={2} direction={["column", "row"]}>
                <TextField
                  id="city"
                  label="City"
                  variant="outlined"
                  value={quote.item.city}
                  fullWidth
                  onChange={(e) =>
                    dispatch(
                      updateProperty({ prop: "city", value: e.target.value })
                    )
                  }
                  error={errors.city !== ""}
                  helperText={errors.city}
                  disabled={isBusy}
                />
                <TextField
                  id="zipcode"
                  label="Zipcode"
                  variant="outlined"
                  value={quote.item.zipcode}
                  fullWidth
                  onChange={(e) =>
                    dispatch(
                      updateProperty({ prop: "zipcode", value: e.target.value })
                    )
                  }
                  error={errors.zipcode !== ""}
                  helperText={errors.zipcode}
                  disabled={isBusy}
                />
              </Stack>
              <FormControl error={errors.state}>
                <Autocomplete
                  disablePortal
                  id="state"
                  options={states}
                  value={quote.item.state ? quote.item.state : undefined}
                  onChange={(event, newValue) => {
                    setState(newValue);
                  }}
                  inputValue={stateInput}
                  onInputChange={(event, newInputValue) => {
                    setStateInputValue(newInputValue);
                  }}
                  fullWidth
                  sx={{ flex: 4 }}
                  renderInput={(params) => (
                    <TextField {...params} label="State" />
                  )}
                />
                <FormHelperText>{errors.state}</FormHelperText>
              </FormControl>
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={
                  !quote.item.address1 ||
                  !quote.item.city ||
                  !quote.item.zipcode ||
                  isBusy
                }
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
      <Box sx={{ mt: 6 }}>
        <Stack gap={2}>
          <Button
            color="inherit"
            onClick={() =>
              dispatch(updateProperty({ prop: "product_type", value: null }))
            }
          >
            I'm not here for third party insurance
          </Button>
        </Stack>
      </Box>
    </>
  );
}

//   return (
//     <>
//       <DialogIntro
//         messages={[
//           <>
//             Hi <b>{quote.item.first_name}</b>.
//           </>,
//           <>Currently I can't help you online.</>,
//         ]}
//       />

//     </>
//   );
// }
