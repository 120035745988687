import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import CloseIcon from "@mui/icons-material/Close";
import AlertBar from "./AlertBar";

const DialogEdit = ({
  title,
  open,
  onClose,
  onSubmit,
  onSubmitCaption = "Update",
  actions,
  children,
  contentPadding = 3,
  size = "md",
  fullScreen = "auto",
  error,
  resetError,
  isBusy = false,
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const paperProps =
    fullScreen === "auto"
      ? {
          sx: {
            position: "fixed",
            top: ["0px", "7px", "20px"],
            margin: ["0px", "20px"],
            borderRadius: "2px",
            width: ["100%", "calc(100% - 32px)"],
          },
        }
      : {
          sx: {
            position: "fixed",
            top: ["0px"],
            margin: ["0px"],
            borderRadius: "2px",
            width: ["100%"],
          },
        };

  return (
    <Dialog
      onClose={(e, reason) => {
        if (reason !== "backdropClick") onClose();
      }}
      open={open}
      PaperProps={paperProps}
      maxWidth={size}
      fullWidth
      fullScreen={fullScreen === "auto" ? isFullScreen : fullScreen}
    >
      <DialogTitle sx={{ bgcolor: "secondary.main" }}>
        {/* BEGIN - Dialog title */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              color: "#f1f1f1",
              fontWeight: "500",
              fontSize: "0.85rem",
              letterSpacing: "2px",
              textTransform: "uppercase",
              whiteSpace: "nowrap",
              overflow: "hidden",
              pt: "3px",
              pl: 2,
            }}
          >
            {title}
          </Box>
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={() => onClose()}
              sx={{
                ml: 1,
                color: "white",
                opacity: "0.5",
                transition: "all 0.2s ease-in-out",
                ":hover": { opacity: 1 },
              }}
              size="small"
              disabled={isBusy}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {/* END - Dialog title */}
      </DialogTitle>
      <DialogContent sx={{ padding: contentPadding }} dividers>
        {children}
      </DialogContent>
      {onSubmit || actions || error ? (
        <DialogActions>
          <Box sx={{ width: "100%" }}>
            <Box>
              <AlertBar error={error} onClose={resetError} />
            </Box>
            <Box sx={{ textAlign: "right" }}>
              {onSubmit ? (
                <>
                  {onClose && onClose !== onSubmit && (
                    <Button disabled={isBusy} onClick={onClose}>
                      Cancel
                    </Button>
                  )}
                  {onSubmit && (
                    <Button
                      disabled={isBusy}
                      variant="contained"
                      onClick={onSubmit}
                      sx={{ width: "100px", height: "35px" }}
                    >
                      {isBusy ? (
                        <CircularProgress size={18} />
                      ) : (
                        onSubmitCaption
                      )}
                    </Button>
                  )}
                </>
              ) : (
                <Box>{actions} here</Box>
              )}
            </Box>
          </Box>
        </DialogActions>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default DialogEdit;
