import axios from "axios";

function isErrorWithMessage(error) {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    error.message === "string"
  );
}

function toErrorWithMessage(maybeError) {
  if (isErrorWithMessage(maybeError)) return maybeError;

  if (maybeError.message) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error) {
  return toErrorWithMessage(error).message;
}

export function handleAxiosError(error) {
  // console.log("error", error);
  if (axios.isAxiosError(error)) {
    if (error.response && error.response.status) {
      if (error.response.status === 0)
        return "Service Unavailable. No response received from the server.";

      // Check if we returned some error messages
      if (error.response.data?.errors) {
        return error.response.data?.errors;
      } else if (error.response.data?.message) {
        return error.response.data?.message;
      }

      // Check if we have statusText
      if (error.response.status && error.response.statusText) {
        return error.response.status + " - " + error.response.statusText;
      }
    }
  }

  return toErrorWithMessage(error).message;
}
