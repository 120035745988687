import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

// Components
import logo from "../../assets/logo.png";
import Layout from "../layout/Layout";
import AppBar from "./AppBar";
import Toolbar from "@mui/material/Toolbar";
import Footer from "./Footer";

// State
import { STATUS_LOADING } from "../../app/status";
import { resetStatus } from "../../app/features/authSlice";

//******************************************************************************
export default function LayoutMain({
  appTitle = process.env.REACT_APP_NAME,
  title,
  children,
  options,
  isLoading,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <Layout appTitle={appTitle} title={title}>
      <AppBar />
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        {/* Add the toolbar for the correct top spacing */}
        <Toolbar
          sx={{
            displayPrint: "none",
          }}
        />
        {children}

        {/* Footer */}
        <Box
          sx={{
            marginTop: "auto",
            fontSize: "1.2rem",
          }}
          component="footer"
        >
          <Footer />
        </Box>
      </Box>
    </Layout>
  );
}
