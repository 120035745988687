import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import { products } from "../../app/lists/products";
import {
  validateCharacterCount,
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import {
  updateProperty,
  payment,
  setDialogOpen,
} from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepPaymentConfirmation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quote = useSelector((state) => state.quote);

  const [isBusy, setIsBusy] = useState(false);

  const doPayment = () => {
    const getCallBackUrl = () => {
      setIsBusy(true);
      dispatch(payment())
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          if (originalPromiseResult.quote.authorization_url) {
            dispatch(setDialogOpen(false));
            navigate(
              `/payment?authorization_url=${originalPromiseResult.quote.authorization_url}`
            );
          }
        })
        .catch((rejectedValueOrSerializedError) => {})
        .finally(function () {
          setIsBusy(false);
        });
    };

    if (quote.item.authorization_url) {
      window.location.href = quote.item.authorization_url;
      setIsBusy(false);
    } else getCallBackUrl();
  };

  const showForm = () => {
    const el = document.getElementById(`user-form`);
    el.classList.remove("hidden");
  };

  useEffect(() => {
    const timer = setTimeout(
      showForm,
      quote.item.product_type === "car" ? 1000 : 1000
    );
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <DialogIntro
        isLoading={
          !quote.item.first_name &&
          !quote.item.last_name &&
          !quote.item.car_make
        }
        messages={
          quote.item.product_type === "car"
            ? [<>Thank you for choosing us!</>]
            : [<>Thank you for choosing us!</>]
        }
      />

      <Box id="user-form" className={"hidden"}>
        <DialogForm
        // messages={[
        //   ((quote.item.product_type === "car" && quote.item.car_make) ||
        //     (quote.item.product_type === "third" && quote.item.address1)) && (
        //     <>
        //       I am <b>{quote.item.first_name + " " + quote.item.last_name}</b>{" "}
        //       and I want the best quote for{" "}
        //       {quote.item.product_type === "car"
        //         ? `Car Insurance for my ${quote.item.car_make}`
        //         : "Third Party Insurance"}
        //     </>
        //   ),
        // ]}
        >
          <form id={"editForm"}>
            <Stack gap={2}>
              <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "flex-start" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={quote.item.accept_pay}
                      onChange={(e) => {
                        dispatch(
                          updateProperty({
                            prop: "accept_pay",
                            value: e.target.checked,
                          })
                        );
                      }}
                      sx={{ p: 0, pl: 1 }}
                    />
                  }
                  label={
                    <Box sx={{ fontSize: "0.8rem", pl: 1 }}>
                      I have read, understand, and agree that:
                      <ol style={{ marginLeft: "0px", paddingLeft: "10px" }}>
                        <li>
                          An in-person inspection is required within 30 days of
                          policy issuance to avoid cancellation. Until the
                          inspection is completed, coverage for claims is
                          limited to 10% of the full vehicle value.
                        </li>
                        <li>
                          If the insured value of my vehicle, at the time of
                          policy inception, differs from the actual market
                          value, I will be responsible for the difference. As a
                          result, any claim payment will be reduced
                          proportionally, according to the Allianz Nigeria
                          Insurance Limited motor insurance policy's average
                          clause.
                        </li>
                      </ol>
                    </Box>
                  }
                  sx={{ alignItems: "flex-start" }}
                />
              </Stack>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  variant="contained"
                  sx={{ minWidth: "150px" }}
                  disabled={
                    !quote.item.accept_pay ||
                    isBusy ||
                    quote.status === STATUS_LOADING
                  }
                  onClick={doPayment}
                >
                  Pay Now
                </Button>
              </Box>
            </Stack>
          </form>
        </DialogForm>
      </Box>
    </>
  );
}
