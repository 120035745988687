// Components
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";

// Components
import Layout from "../../components/layout/LayoutLegal";

// State
import { STATUS_IDLE, STATUS_LOADING } from "../../app/status";
import { resetQuote } from "../../app/features/quoteSlice";
import { getLegal } from "../../app/features/themeSlice";

//******************************************************************************
export default function Privacy() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);

  const removeStoredSettings = () => {
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    sessionStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_DEVICE_ID);
    sessionStorage.removeItem(process.env.REACT_APP_DEVICE_ID);
    localStorage.removeItem(process.env.REACT_APP_QUOTE);
    sessionStorage.removeItem(process.env.REACT_APP_QUOTE);
    dispatch(resetQuote());
  };

  // Initialize - Application Settings
  // useEffect(() => {
  //   // Only get legal when we don't have it
  //   if (theme.legal.privacy === STATUS_IDLE) {
  //     // Load Legal
  //     dispatch(getLegal({ page: "privacy" }))
  //       .then(unwrapResult)
  //       .then((originalPromiseResult) => {})
  //       .catch((rejectedValueOrSerializedError) => {});
  //   }
  // }, [theme.legal.privacy]);

  return (
    <Layout title="Privacy Policy" isLoading={theme.status === STATUS_LOADING}>
      <h2>HOW WE USE YOUR DATA</h2>
      <p>
        Allianz Nigeria cares about protecting your personal data disclosed to
        us. We will therefore ensure your data is:
      </p>
      <ol type="a">
        <li>Processed lawfully;</li>
        <li>Collected and kept for a specific purpose;</li>
        <li>Retained no longer than necessary; and </li>
        <li>Kept securely. </li>
      </ol>

      <p>
        Allianz Nigeria will use the personal information you provide to advise
        you on your risk and premium payment. We may also use your information
        to perform credit checks and for marketing purposes. For more details,
        please read up our data protection policy on{" "}
        <Link
          href="https://www.allianz.ng/Privacy-Statement.html"
          target="_blank"
        >
          https://www.allianz.ng/Privacy-Statement.html
        </Link>
        .
      </p>
      <p>
        This policy sets out your rights under the Data Protection (Nigeria Data
        Protection Regulation 2019) and explains how you can access your
        personal information and complain if you have concerns about how we are
        using it.
      </p>
      <p>
        By accepting the premium and making a payment, you agree that you
        understand how your data will be used and consent to its use as stated
        in our privacy policy.
      </p>
      <p>Updating your personal information</p>
      <p>
        You can access, make changes to, update or withdraw your information
        provided to us by contacting us through the following channels:
      </p>
      <p>
        <b>Allianz Nigeria Insurance LTD:</b>
        <br />
        95 Broad Street, Lagos Island, Lagos
        <br />
        Tel: 0800 255 4269
        <br /> Email: info@allianz.ng
      </p>

      <Button variant="outlined" onClick={removeStoredSettings}>
        Remove all cookies
      </Button>

      {/* {theme.status === STATUS_LOADING ||
      theme.legal.privacy === STATUS_IDLE ? (
        <Stack>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width={"30%"} sx={{ mb: 2 }} />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton width={"20%"} />
        </Stack>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: theme.legal.privacy }} />
      )} */}
    </Layout>
  );
}
