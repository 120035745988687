import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Components
import AlertBar from "../../components/AlertBar";

// State
import jdpApi from "../../app/jdpApi";
import { handleAxiosError } from "../../functions/errorWithMessage";

import {
  validateEmail,
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";

export default function FormContact({ embedded = true, onSubmit }) {
  const auth = useSelector((state) => state.auth);
  const quote = useSelector((state) => state.quote.item);
  const [isBusy, setIsBusy] = useState(false);
  const [errMsg, setErrMsg] = useState();
  const [isSend, setIsSend] = useState(false);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    if (values.name === "") {
      setValues({
        ...values,
        name: quote.first_name + " " + quote.last_name,
      });
    }

    if (values.email === "") {
      setValues({
        ...values,
        email: quote.email,
      });
    }

    if (values.phone === "") {
      setValues({
        ...values,
        phone: quote.phone,
      });
    }
    return () => {};
  }, [quote, values]);

  const handleChange = (prop) => (e) => {
    setValues({ ...values, [prop]: e.target.value });
    setErrors({ ...errors, [prop]: "" });
  };

  const sendEmail = async () => {
    setErrMsg();
    const response = await jdpApi
      .post("/contact", values)
      .then(function (response) {
        // handle success
        setIsSend(true);
      })
      .catch(function (error) {
        // handle error
        setErrMsg(handleAxiosError(error));
      })
      .finally(function () {
        // always executed
        setIsBusy(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsBusy(true);

    const tmpErr = {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    };

    // Validate the fields
    if (values.name === "") tmpErr.name = "Please enter your name.";
    if (values.subject === "") tmpErr.subject = "Please enter a subject.";
    if (values.message === "") tmpErr.message = "Please enter your message.";

    // Validate email
    if (values.email === "") tmpErr.email = "Please enter your email address.";
    else if (!validateEmail(values.email))
      tmpErr.email = "Invalid email address.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      sendEmail();
    } else {
      // Something went wrong
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  if (isSend) {
    return (
      <>
        <Alert variant="outlined" severity="success">
          Your message has been sent.
        </Alert>
      </>
    );
  }

  return (
    <form id="contactForm" noValidate onSubmit={handleSubmit}>
      <Stack sx={{ width: "100%" }} spacing={2}>
        <TextField
          id="name"
          label="Name"
          type="text"
          value={values.name}
          onChange={handleChange("name")}
          error={errors.name !== ""}
          helperText={errors.name}
          required
          disabled={isBusy}
          fullWidth
          inputProps={{ maxLength: 50 }}
        />
        <TextField
          id="email"
          label="Email"
          type="email"
          value={values.email}
          onChange={handleChange("email")}
          error={errors.email !== ""}
          helperText={errors.email}
          required
          disabled={isBusy}
          fullWidth
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          id="phone"
          label="Phone"
          type="tel"
          value={values.phone}
          onChange={handleChange("phone")}
          error={errors.phone !== ""}
          helperText={errors.phone}
          disabled={isBusy}
          fullWidth
          inputProps={{ maxLength: 20 }}
        />
        <TextField
          id="subject"
          label="Subject"
          type="text"
          value={values.subject}
          onChange={handleChange("subject")}
          error={errors.subject !== ""}
          helperText={errors.subject}
          disabled={isBusy}
          fullWidth
          inputProps={{ maxLength: 150 }}
        />
        <TextField
          id="message"
          label="Message"
          type="text"
          value={values.message}
          onChange={handleChange("message")}
          error={errors.message !== ""}
          helperText={errors.message}
          disabled={isBusy}
          fullWidth
          InputProps={{
            rows: 5,
            multiline: true,
            inputComponent: "textarea",
          }}
        />
        <AlertBar error={errMsg} onClose={() => setErrMsg()} />
        {embedded && (
          <Box sx={{ textAlign: "right" }}>
            <Button
              type="submit"
              variant="contained"
              color={"primary"}
              disabled={isBusy}
              sx={{ width: "150px", mt: 2 }}
            >
              Send
            </Button>
          </Box>
        )}
      </Stack>
    </form>
  );
}
