import React, { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

//******************************************************************************
export default function Layout({
  appTitle = process.env.REACT_APP_NAME,
  title,
  children,
  isLoading,
}) {
  const MAIN_TITLE = appTitle;
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (title && title !== MAIN_TITLE) {
      document.title = title + " | " + MAIN_TITLE;
    } else document.title = "" + MAIN_TITLE;
    window.scrollTo(0, 0);
  }, [title, MAIN_TITLE]);

  return (
    <>
      <CssBaseline />
      <Box>{children}</Box>
    </>
  );
}
