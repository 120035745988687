import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

// Components
import AlertBar from "../../components/AlertBar";
import DialogIntro from "./DialogIntro";
import ProductItem from "../products/ProductItem";
import ProductCheckout from "../products/ProductCheckout";

// State
import { products } from "../../app/lists/products";
import { STATUS_FAILED, STATUS_LOADING, STATUS_SUCCES } from "../../app/status";
import {
  verifyOrder,
  resetStatus,
  setDialogOpen,
} from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepRestart() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  const cur_product = products
    .filter((item) => item.product_type === quote.item.product_type)
    .map((item) => item)[0];

  const verifyOrderReference = () => {
    dispatch(verifyOrder())
      .then(unwrapResult)
      .then((originalPromiseResult) => {})
      .catch((rejectedValueOrSerializedError) => {})
      .finally(() => setIsBusy(false));
  };

  useEffect(() => {
    if (quote.item.uuid) {
      verifyOrderReference();
    } else {
      setErrMsg("Invalid reference");
      setIsBusy(false);
    }

    return () => {
      setErrMsg();
      dispatch(resetStatus());
      setIsBusy(true);
    };
  }, [quote.uuid]);

  // Busy loading
  if (isBusy || quote.status === STATUS_LOADING) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // On error
  if (quote.error || errMsg) {
    return (
      <Box sx={{ textAlign: "center", m: 2 }}>
        <AlertBar error={errMsg} />
        <AlertBar
          error={quote.error + "test"}
          onClose={() => dispatch(resetStatus())}
          sx={{ my: 2 }}
        />
        <Button variant="contained" onClick={() => verifyOrderReference()}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <>
      <ProductCheckout product={cur_product} />
    </>
  );
}
