import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

// Components
import logo from "../../assets/logo-light.png";
import LayoutMain from "../layout/LayoutMain";
import PageContent from "../auth/PageContent";
import AlertSnackBar from "../AlertSnackBar";

// State
import { STATUS_LOADING } from "../../app/status";
import { resetStatus } from "../../app/features/authSlice";

//******************************************************************************
export default function Layout({
  appTitle = process.env.REACT_APP_NAME,
  title,
  children,
  options,
  isLoading,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  return (
    <LayoutMain appTitle={appTitle} title={title}>
      {(isLoading || auth.status.status === STATUS_LOADING) && (
        <LinearProgress
          sx={{ position: "absolute", top: 0, height: "5px", width: "100%" }}
        />
      )}

      <Box
        sx={{
          displayPrint: "none",
          p: [0, 2, 3, 4],
        }}
      >
        <Container maxWidth="sm" disableGutters>
          <Box>
            <PageContent title={title} options={options}>
              {children}
            </PageContent>
          </Box>
        </Container>
      </Box>
      {/* <AlertSnackBar
        error={auth.error}
        onClose={() => dispatch(resetStatus())}
      /> */}
    </LayoutMain>
  );
}
