// Valid email address
export const validateEmail = (value) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
  return reg.test(value);
};

// Validate count
export const validateCharacterCount = (value, count) => {
  return value.length >= count;
};

// Has uppercase character
export const hasUppercaseCharacter = (value) => {
  let reg = /(?=.*[A-Z])/;
  return reg.test(value) !== false;
};

// Has lowercase character
export const hasLowercaseCharacter = (value) => {
  let reg = /(?=.*[a-z])/;
  return reg.test(value) !== false;
};

// Has number
export const hasNumber = (value) => {
  let reg = /\d/;
  return reg.test(value) !== false;
};

// Validate Password
export const validatePassword = (value) => {
  return (
    validateCharacterCount(value, 8) &&
    hasUppercaseCharacter(value) &&
    hasLowercaseCharacter(value) &&
    hasNumber(value)
  );
};

// South African ID
export const isZAId = (value) => {
  let reg =
    /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
  return reg.test(value) !== false;
};

export const isNGId = (value) => {
  let reg = /^[0-9]{11}$/;
  return reg.test(value) !== false;
};

// Validate if form has error
export const validateFormErrors = (errors) => {
  for (var key in errors) {
    if (errors[key] !== "") {
      return true;
    }
  }
  return false;
};

// Animate form on error
export const animateForm = (formName) => {
  const currentForm = document.getElementById(formName);
  if (currentForm) {
    currentForm.classList.add("bounceAnimation");
    setTimeout(() => {
      currentForm.classList.remove("bounceAnimation");
    }, 500);
  }
  return;
};
