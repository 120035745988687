import { combineReducers, configureStore } from "@reduxjs/toolkit";

import authReducer from "./features/authSlice";
import quoteReducer from "./features/quoteSlice";
import contactReducer from "./features/contactSlice";
import themeReducer from "./features/themeSlice";

const combinedReducer = combineReducers({
  auth: authReducer,
  quote: quoteReducer,
  contact: contactReducer,
  theme: themeReducer,
  // mall: mallReducer,
  // mallProducts: mallProductsReducer,
  // mallCart: mallCartReducer,
  // mallOrder: mallOrderReducer,
});

const rootReducer = (state, action) => {
  // Check if reset of store is requested
  if (action.type === "root/logout") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
