import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";

import agent from "../../assets/agent.png";

//******************************************************************************
const AgentAvatar = ({ name }) => {
  return (
    <Avatar alt={name} src={agent} sx={{ height: "50px", width: "50px" }} />
  );
};

//******************************************************************************
export default function Agent({
  name = process.env.REACT_APP_AGENT_NAME,
  showProfile,
}) {
  if (showProfile) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <AgentAvatar name={name} />
        <Box sx={{ flexGrow: 1 }}>
          <Stack>
            <Box sx={{ fontSize: "1.0rem", lineHeight: "1.3rem" }}>
              I'm {name}
              <br /> from {process.env.REACT_APP_NAME}
            </Box>
          </Stack>
        </Box>
      </Box>
    );
  }

  return <AgentAvatar name={name} />;
}
