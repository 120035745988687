import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useMemo } from "react";

import useLocalStorage from "../hooks/useLocalStorage";

export const ThemeContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
  toggleColorMode: () => {},
});

export default function Theme({ children }) {
  const [isLoading, indicateLoading] = useState(false);

  const [mode, setMode] = useLocalStorage("theme", "light");
  const themeUtils = useMemo(
    () => ({
      isLoading,
      setIsLoading: (value) => {
        indicateLoading(value);
      },
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [isLoading]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          contrastThreshold: 2.4,
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                primary: {
                  main: "#F86200",
                  dark: "#F86200",
                  // main: "#143861",
                },
                secondary: {
                  main: "#00468B",
                },
                background: {
                  default: "#eee",
                  paper: "rgba(255, 255, 255, 1)",
                },
              }
            : {
                // palette values for dark mode
                primary: {
                  main: "#F86200",
                },
                secondary: {
                  main: "#AAC7E5",
                },
                background: {
                  default: "rgba(50, 50, 50, 1)",
                  paper: "#111111",
                },
              }),
        },

        components: {
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: mode === "light" ? "#fff" : "#0f0f0f",
              },
            },
          },

          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "20px",
                textTransform: "none",
                fontWeight: "500",
                padding: "7px 25px",
              },
              startIcon: {
                marginRight: "14px",
              },
            },
          },
        },
        typography: {
          allVariants: {
            color: "#00468B",
          },
          htmlFontSize: 16,
          h1: {
            fontSize: "2rem",
          },
          h2: {
            fontSize: "1.8rem",
          },
          h3: {
            fontSize: "1.6rem",
          },
          h4: {
            fontSize: "1.4rem",
          },
          h5: {
            fontSize: "1.2rem",
          },
          h6: {
            fontSize: "1.1rem",
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={themeUtils}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
