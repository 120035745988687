import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import UserDialog from "./UserDialog";

//******************************************************************************
export default function DialogForm({ messages, children }) {
  const textBoxStyle = {
    pt: 4,
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Stack gap={1}>
        {messages?.map(
          (message, index) =>
            message && (
              <Box key={index} sx={{ textAlign: "right" }}>
                <UserDialog>{message}</UserDialog>
              </Box>
            )
        )}
      </Stack>
      <Box sx={textBoxStyle}>{children}</Box>
    </Box>
  );
}
