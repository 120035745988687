import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import jdpApi from "../jdpApi";
import { handleAxiosError } from "../../functions/errorWithMessage";
import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCES,
  STATUS_FAILED,
  STATUS_UPDATE,
} from "../status";
import { clContact } from "../objects/clContact";

const API_ROOT = "/contact";

// Send Email
export const sendEmail = createAsyncThunk(
  "contact/sendEmail",
  async ({ name, email, phone, subject, message }, thunkAPI) => {
    try {
      const company_id = process.env.REACT_APP_COMPANY_ID;

      // Send request to API
      const response = await jdpApi.post(API_ROOT, {
        name,
        email,
        phone,
        subject,
        message,
        company_id,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Structure of initial state
const initialState = {
  item: clContact(),
  dialog_open: false,
  status: STATUS_IDLE,
  error: "",
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  // Actions
  reducers: {
    resetStatus: (state, action) => {
      state.status = STATUS_IDLE;
      state.error = "";
    },
    setDialogOpen: (state, action) => {
      state.dialog_open = action.payload;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Send Email
      .addCase(sendEmail.pending, (state) => {
        state.status = STATUS_UPDATE;
        state.error = "";
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.status = STATUS_SUCCES;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      });
  },
});

export const { resetStatus, setDialogOpen } = contactSlice.actions;

export default contactSlice.reducer;
