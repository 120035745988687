import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Icons
import HomeIcon from "@mui/icons-material/Home";

// Components
import Layout from "../components/auth/Layout";

//******************************************************************************
export default function PayGateway() {
  let [searchParams] = useSearchParams();

  const url = searchParams.get("authorization_url");

  useEffect(() => {
    if (url) window.location.href = url;
  }, [url]);

  if (url) {
    return (
      <Layout title={"Payment Gateway"}>
        <Box sx={{ textAlign: "center" }}>
          <Typography gutterBottom>
            You are being redirected to PayStack...
          </Typography>
        </Box>
      </Layout>
    );
  } else {
    return (
      <Layout title={"Oops"}>
        <Box sx={{ textAlign: "center" }}>
          <Typography gutterBottom>
            Please specify the payment gateway.
          </Typography>
        </Box>
      </Layout>
    );
  }
}
