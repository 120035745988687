import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// Icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";

// Components
import Layout from "../../components/auth/Layout";
import FormSignIn from "../../components/auth/FormSignIn";
import { NavLink } from "react-router-dom";

//******************************************************************************
export default function PageContent({ title, children, options }) {
  return (
    <>
      <Grid
        container
        columnSpacing={4}
        sx={{ mb: 4, justifyContent: "center" }}
      >
        <Grid item xs={12} sm={12} md={options ? 6 : 12}>
          <Box id={"editForm"}>
            <Divider sx={{ display: ["block", "none"] }} />
            <Box
              sx={{
                backgroundColor: ["background.paper"],
                border: "1px",
                borderStyle: "solid",
                borderColor: ["transparent", "divider"],
                borderRadius: [0, "20px"],
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  p: [2, 4],
                }}
              >
                <Typography
                  sx={{
                    color: "secondary.main",
                    letterSpacing: "2px",
                    fontWeight: "300",
                    fontSize: ["1.3rem", "1.5rem", "1.6em"],
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  {title}
                </Typography>
                {children}
              </Box>
            </Box>
            <Divider sx={{ display: ["block", "none"] }} />
          </Box>
        </Grid>
        {options && (
          <Grid item xs={12} sm={12} md={6}>
            {options}
          </Grid>
        )}
      </Grid>
    </>
  );
}
