import { parseData } from "../../functions/parseData";

// Initialize Object from Data
export const clContact = (data) => {
  let output = {
    id: "",
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    send_date: null,
  };
  let outputType = {
    send_date: "date",
  };

  if (data) {
    output = parseData(output, outputType, data);
  }
  return output;
};
