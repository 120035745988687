import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

// Components
import logo from "../../assets/logo-512-light.png";
import logodark from "../../assets/logo-512-dark.png";

export default function ButtonHome({ toHome = "/" }) {
  const muiTheme = useTheme();
  const theme = useSelector((state) => state.theme);
  const quote = useSelector((state) => state.quote);

  return (
    <Box sx={{ pt: "5px" }}>
      <Link
        alt="Go Home"
        to={toHome}
        component={NavLink}
        sx={[
          {
            "&>img": {
              transition: "all .2s ease-in-out",
            },
            "&>p": {
              transition: "all .2s ease-in-out",
            },
          },
          {
            "&>img:hover": {
              transform: "scale(1.1)",
              //filter: "drop-shadow(0 0 4px #555)",
            },
            "&>p:hover": {
              fontWeight: 800,
            },
          },
        ]}
      >
        <img
          src={
            muiTheme.palette.mode === "light"
              ? theme.theme.logo.light
              : theme.theme.logo.dark
          }
          style={{
            maxHeight: "30px",
            height: "100%",
            maxWidth: "100%",
          }}
          alt={process.env.REACT_APP_NAME}
        />
      </Link>
    </Box>
  );
}
