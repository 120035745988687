import React, { useState, useEffect, useMemo } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { createTheme, ThemeProvider } from "@mui/material";

import "./css/main.css";

// Legal
import Terms from "./pages/legal/Terms";
import Privacy from "./pages/legal/Privacy";

// Default
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import VerifyEmail from "./pages/VerifyEmail";
import OrderConfirmation from "./pages/OrderConfirmation";
import PayGateway from "./pages/PayGateway";

// Authentication
// import AuthHome from "./pages/auth/Index";
import AuthSignOut from "./pages/auth/SignOut";
// import AuthSignIn from "./pages/auth/SignIn";
// import AuthRegister from "./pages/auth/Register";
// import AuthPasswordForgot from "./pages/auth/PasswordForgot";
// import AuthPasswordReset from "./pages/auth/PasswordReset";
// import AuthPasswordUpdate from "./pages/auth/PasswordUpdate";
// import AuthVerifyEmail from "./pages/auth/VerifyEmail";

import DialogGetQuote from "./components/quote/DialogGetQuote";
import DialogContact from "./components/contact/DialogContact";

// State
import jdpApi from "./app/jdpApi";
import {
  initializeAuth,
  setOnlineStatus,
  signOut,
} from "./app/features/authSlice";
import { initializeQuote } from "./app/features/quoteSlice";
import { initializeTheme } from "./app/features/themeSlice";

//******************************************************************************
export default function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isBusy, setIsBusy] = useState(true);
  const currentTheme = useSelector((state) => state.theme);

  // Initialize - Application Settings
  useEffect(() => {
    // Load access
    const access = jdpApi.get(`/access`);

    // Load the theme
    dispatch(initializeTheme())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        if (originalPromiseResult.theme.favicon.light) {
          document.querySelector("link[rel='shortcut icon']").href =
            originalPromiseResult.theme.favicon.light;

          document.querySelector("link[rel*='icon']").href =
            originalPromiseResult.theme.favicon.light;
        }

        // Get the legal information
        //dispatch(getLegal({ id: process.env.REACT_APP_COMPANY_ID }));
      })
      .catch((rejectedValueOrSerializedError) => {})
      .finally(() => {
        setIsBusy(false);

        // // Initialize authententication
        // dispatch(initializeAuth())
        //   .then(unwrapResult)
        //   .then((originalPromiseResult) => {})
        //   .catch((rejectedValueOrSerializedError) => {})
        //   .finally(() => {
        //     setIsBusy(false);
        //   });
      });

    //Initialize Quote
    dispatch(initializeQuote())
      .then(unwrapResult)
      .then((originalPromiseResult) => {})
      .catch((rejectedValueOrSerializedError) => {})
      .finally(function () {
        // always executed
        //setIsBusy(false);
      });

    //Validate token change
    window.addEventListener("storage", (e) => {
      if (e.key === process.env.REACT_APP_ACCESS_TOKEN) {
        if (e.newValue === null || e.newValue === undefined)
          dispatch(signOut());
      }
    });

    //Connection status - online
    window.addEventListener("online", () => {
      if (process.env.NODE_ENV === "development")
        console.log("Connection online.");
      dispatch(setOnlineStatus());
    });
    // Connection status - offline
    window.addEventListener("offline", () => {
      if (process.env.NODE_ENV === "development")
        console.log("Connection offline.");
      dispatch(setOnlineStatus());
    });
    //setIsBusy(false);
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          contrastThreshold: 2.4,
          primary: {
            main: currentTheme.theme.palette.primary.main,
          },
          secondary: {
            main: currentTheme.theme.palette.secondary.main,
          },
          background: {
            default: currentTheme.theme.palette.background.default,
            paper: currentTheme.theme.palette.background.paper,
          },
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: "#fff",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "20px",
                textTransform: "none",
                fontWeight: "500",
                padding: "7px 25px",
              },
              startIcon: {
                marginRight: "14px",
              },
            },
          },
        },
        typography: {
          h1: {
            fontSize: "2rem",
          },
          h2: {
            fontSize: "1.8rem",
          },
          h3: {
            fontSize: "1.6rem",
          },
          h4: {
            fontSize: "1.4rem",
          },
          h5: {
            fontSize: "1.2rem",
          },
          h6: {
            fontSize: "1.1rem",
          },
        },
      }),
    [currentTheme.theme]
  );

  // Busy with application state
  if (isBusy) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Routes>
          {/* Legal Pages */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />

          {/* Authentication */}
          {/* <Route path="/auth/signout" element={<AuthSignOut />} /> */}
          {/*<Route
          path="/auth/signin"
          element={auth.signed_in ? <Navigate to="/" /> : <AuthSignIn />}
        />
        <Route
          path="/auth/register"
          element={auth.signed_in ? <Navigate to="/" /> : <AuthRegister />}
        />
        <Route path="/auth/password/reset" element={<AuthPasswordReset />} />
        <Route
          path="/auth/password"
          element={
            auth.signed_in ? <AuthPasswordUpdate /> : <AuthPasswordForgot />
          }
        />
        <Route path="/auth/verify" element={<AuthVerifyEmail />} />
        <Route
          path="/auth"
          element={auth.signed_in ? <AuthHome /> : <AuthSignIn />}
        /> */}
          <Route path="/payment" element={<PayGateway />} />
          <Route path="/order" element={<OrderConfirmation />} />
          <Route path="/verify" element={<VerifyEmail />} />
          <Route path="/" element={<Home />} />

          {/* Page not found */}
          <Route path="*" element={<NotFound />} />
        </Routes>

        <DialogGetQuote />
        <DialogContact />
      </ThemeProvider>
    </>
  );
}
