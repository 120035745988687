import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";

// Icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

// Components
import Agent from "./Agent";
import AgentDialog from "./AgentDialog";
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import ButtonOption from "./ButtonOption";

// State
import { formatCurrency } from "../../functions/formatData";
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCarValue() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const theme = useSelector((state) => state.theme);
  const [isBusy, setIsBusy] = useState(false);
  const [value, setValue] = useState(
    quote.item.car_value === 0
      ? 25000000
      : Math.round(quote.item.car_value / 100)
  );

  const [errors, setErrors] = useState({
    car_value: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      car_value: "",
    };

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(
        updateProperty({
          prop: "car_value",
          value: value * 100,
        })
      );

      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            What is the value of your{" "}
            <b>
              {quote.item.car_year} {quote.item.car_make} {quote.item.car_model}
            </b>
            ?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Stack gap={2} direction={["column"]}>
              <Slider
                value={value}
                onChange={(e) => setValue(e.target.value)}
                step={100000}
                min={5000000}
                max={300000000}
                aria-label="Default"
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box>
                  <IconButton
                    onClick={() => setValue(value - 100000)}
                    disabled={value <= 5000000}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    border: "1px solid",
                    borderColor: "divider",
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    p: 1,
                  }}
                >
                  {formatCurrency(
                    Math.round(value),
                    theme.theme.currency,
                    theme.theme.language
                  )}
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setValue(value + 100000)}
                    disabled={value >= 300000000}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </Stack>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
