import React from "react";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

// Icons
import CloseIcon from "@mui/icons-material/Close";

//******************************************************************************
export default function AlertSnackBar({ error, severity = "error", onClose }) {
  if (error) {
    return (
      <Snackbar
        open={error ? true : false}
        autoHideDuration={10000}
        onClose={onClose}
      >
        <Alert
          variant="filled"
          severity={severity}
          action={
            onClose ? (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            ) : null
          }
          sx={{ mb: 2 }}
        >
          {Array.isArray(error)
            ? error.map((message, index) => <div key={index}>{message}</div>)
            : error}
        </Alert>
      </Snackbar>
    );
  } else return null;
}
