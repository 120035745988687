import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

// Components
import ProductCheckout from "../products/ProductCheckout";
import HelpButton from "../HelpButton";

// State
import { products } from "../../app/lists/products";
import {} from "../../functions/inputValidation";
import {
  nextStep,
  checkout,
  payment,
  resetStatus,
  setDialogOpen,
} from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepCheckout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(true);

  const cur_product = products
    .filter((item) => item.product_type === quote.item.product_type)
    .map((item) => item);

  // Actions in the Checkout Page
  const doPayment = () => {
    if (quote.item.product_type === "car") {
      dispatch(nextStep());
    } else {
      gotoPayment();
    }
  };

  const gotoPayment = () => {
    const getCallBackUrl = () => {
      setIsBusy(true);
      dispatch(payment())
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          if (originalPromiseResult.quote.authorization_url) {
            dispatch(setDialogOpen(false));
            navigate(
              `/payment?authorization_url=${originalPromiseResult.quote.authorization_url}`
            );
          }
        })
        .catch((rejectedValueOrSerializedError) => {})
        .finally(function () {
          setIsBusy(false);
        });
    };

    if (quote.item.authorization_url) {
      window.location.href = quote.item.authorization_url;
      setIsBusy(false);
    } else getCallBackUrl();
  };

  const doPayment2 = () => {
    const getCallBackUrl = () => {
      setIsBusy(true);
      dispatch(payment())
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          if (originalPromiseResult.quote.authorization_url) {
            dispatch(setDialogOpen(false));
            navigate(
              `/payment?authorization_url=${originalPromiseResult.quote.authorization_url}`
            );
          }
        })
        .catch((rejectedValueOrSerializedError) => {})
        .finally(function () {
          setIsBusy(false);
        });
    };

    if (quote.item.authorization_url) {
      window.location.href = quote.item.authorization_url;
      setIsBusy(false);
    } else getCallBackUrl();
  };

  // useEffect(() => {
  //   if (quote.item.authorization_url) {
  //     console.log("Redirect:", quote.item.authorization_url);
  //     //window.location.href = quote.item.authorization_url;
  //   }
  // }, [quote.item.authorization_url]);

  // Inilize Page
  useEffect(() => {
    // if (quote.item.email_confirmed && !quote.item.authorization_url) {
    dispatch(checkout())
      .then(unwrapResult)
      .then((originalPromiseResult) => {})
      .catch((rejectedValueOrSerializedError) => {})
      .finally(function () {
        // always executed
        setIsBusy(false);
      });

    return () => {
      setIsBusy(true);
    };
  }, []);

  // Loading Checkout
  if (isBusy) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(80vh - 30px)",
        }}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  // If we have errors
  if (quote.error) {
    return (
      <Box sx={{ p: 3 }}>
        {/* <Box
          sx={{
            borderRadius: "5px",
            borderColor: "error",
            border: "1px solid",
            p: 2,
          }}
        >
          <Typography sx={{ color: "error", fontWeight: "500" }}>
            We are currently not able to help you
          </Typography>
          <Typography>{quote.error}</Typography>
          <Box sx={{ textAlign: "right" }}>
            <Box sx={{ width: "100px" }}>
              <Button>Help</Button>
            </Box>
          </Box>
        </Box> */}
        <Alert variant="outlined" severity="error">
          <Box sx={{ display: "block" }}>
            <AlertTitle>We are currently not able to help you</AlertTitle>
            <Box>{quote.error}</Box>
            <Box sx={{ width: "100px", mt: 2 }}>
              <HelpButton />
            </Box>
          </Box>
        </Alert>
      </Box>
    );
  }

  // Default output
  return (
    <>
      <ProductCheckout product={cur_product[0]} doPayment={doPayment} />
    </>
  );
}
