import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { format, intervalToDuration } from "date-fns";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import AlertBar from "../AlertBar";

// State
import {
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUser() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    id_number: "",
    birthday: "",
    place_of_birth: "",
    phone: "",
    email: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      birthday: "",
    };

    if (quote.item.birthday) {
      try {
        let tmpDate = new Date(
          format(new Date(quote.item.birthday), "yyyy-MM-dd")
        );

        let distance = intervalToDuration({
          start: new Date(quote.item.birthday),
          end: new Date(),
        });

        if (!distance.years || distance.years < 18) {
          tmpErr.birthday = "You need to be at least 18";
        }
      } catch (e) {
        tmpErr.birthday = "Invalid date";
      }
    } else tmpErr.birthday = "Please enter your birthday";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep()).finally(setIsBusy(false));
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  const setBirthday = (value) => {
    dispatch(
      updateProperty({
        prop: "birthday",
        value: format(new Date(value), "yyyy-MM-dd"),
      })
    );
    setErrors({ ...errors, birthday: "" });
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            When were you born, <b>{quote.item.first_name}</b>?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <FormControl error={errors.birthday}>
              <DatePicker
                label="Birthday"
                disableFuture
                value={quote.item.birthday ? quote.item.birthday : null}
                onChange={(newValue) => setBirthday(newValue)}
              />
            </FormControl>

            <AlertBar
              error={errors.birthday}
              onClose={() => setErrors({ ...errors, birthday: "" })}
            />

            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.birthday || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
