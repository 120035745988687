import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import enGB from "date-fns/locale/en-GB";

import { store } from "./app/store";
import App from "./App";
import ThemeContext from "./context/ThemeContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ThemeContext>
            <App />
          </ThemeContext>
        </BrowserRouter>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>
);
