import { parseData } from "../../functions/parseData";

// Initialize Object from Data
export const clQuote = (data) => {
  let output = {
    id: "",
    uuid: "",
    company_id: process.env.REACT_APP_COMPANY_ID,
    first_name: "",
    last_name: "",
    phone: "",
    phone_confirmed: null,
    phone2: "",
    email: "",
    email_confirmed: null,
    birthday: "",
    //place_of_birth: "",

    id_document: "",
    id_number: "",
    bvn: "",

    accept_terms: false,
    accept_privacy: false,
    accept_form: false,
    accept_pay: false,

    // Insurance Type
    //product_id: null,
    product_type: "",

    // Car
    car_year: 0,
    car_make: "",
    car_model: "",
    car_registration: "",
    car_country: "",
    car_condition: "",
    car_chassis: "",
    car_modified: "",
    car_driver: "",
    car_work: "",

    car_insurance_years: -1,
    car_insurance_claims: -1,
    car_insurance_cancel: "",

    address1: "",
    address2: "",
    zipcode: "",
    city: "",
    state: "",

    // Finance
    income_annual: -1,
    occupation: "",
    // employer: "",
    // employer_address: "",
    // employer_city: "",
    // employer_zipcde: "",
    authorization_url: "",
    amount: 0,
    monthly: 0,
    annual: 0,
    car_value: 0,

    paid_at: "",
    paid_status: "",

    reference_id: "",
    certificate_number: "",
    cover_note: "",
  };
  let outputType = {
    company_id: "int",
    product_id: "int",
    car_year: "int",
    accept_terms: "bool",
    accept_privacy: "bool",
    accept_form: "bool",

    annual: 0,
    monthly: 0,
  };

  if (data) {
    output = parseData(output, outputType, data);
  }
  return output;
};
