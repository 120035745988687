import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Icons
import CloseIcon from "@mui/icons-material/Close";

//******************************************************************************
export default function AlertBar({
  error,
  severity = "error",
  variant = "filled",
  onClose,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const mall = useSelector((state) => state.mall);

  if (error) {
    return (
      <Alert
        variant={variant}
        severity={severity}
        action={
          onClose ? (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          ) : null
        }
        sx={{ mb: 2, width: "100%" }}
      >
        {Array.isArray(error)
          ? error.map((message, index) => <div key={index}>{message}</div>)
          : error}
      </Alert>
    );
  } else return null;
}
