import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import AlertBar from "../../components/AlertBar";
import ButtonLoading from "../ButtonLoading";
import ButtonOption from "./ButtonOption";

// State
import { products } from "../../app/lists/products";
import {
  validateFormErrors,
  animateForm,
  validateEmail,
} from "../../functions/inputValidation";
import {
  updateProperty,
  verifyEmail,
  nextStep,
  updateQuote,
  resendVerificationEmail,
  setStep,
} from "../../app/features/quoteSlice";
import { STATUS_LOADING } from "../../app/status";

//******************************************************************************
export default function StepUserContactEmailVerify() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [emailOTP, setEmailOTP] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [mailIsSent, setMailIsSent] = useState(false);

  const [errors, setErrors] = useState({
    phone: "",
    phone2: "",
    email: "",
    otp: "",
  });

  const validateOTP = () => {
    setIsBusy(true);
    const tmpErr = {
      phone: "",
      phone2: "",
      email: "",
      otp: "",
    };

    if (!emailOTP) tmpErr.otp = "Please enter your OTP";
    else if (emailOTP.length !== 6) tmpErr.otp = "Invalid OTP";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(
        verifyEmail({
          email: quote.item.email,
          id_check: emailOTP,
          quote_id: quote.item.uuid,
        })
      )
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          dispatch(setStep(quote.step + 1));
        })
        .catch((rejectedValueOrSerializedError) => {})
        .finally(function () {
          setIsBusy(false);
        });
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }

    //setIsVerify(true);
  };

  const doResendVerificationEmail = () => {
    setIsBusy(true);
    dispatch(resendVerificationEmail())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setMailIsSent(true);
      })
      .catch((rejectedValueOrSerializedError) => {})
      .finally(function () {
        setIsBusy(false);
      });
  };

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      phone: "",
      phone2: "",
      email: "",
    };

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  if (quote.item.email_confirmed) {
    return (
      <>
        <DialogIntro
          messages={[<>Thank you. Your email has been verified.</>]}
        />

        <DialogForm>
          <form id={"editForm"}>
            <Stack gap={2}>
              <Box sx={{ textAlign: "right" }}>
                <ButtonLoading
                  disabled={
                    !quote.item.email ||
                    isBusy ||
                    quote.status === STATUS_LOADING
                  }
                  isLoading={isBusy || quote.status === STATUS_LOADING}
                  onClick={handleNextStep}
                >
                  Next
                </ButtonLoading>
              </Box>
            </Stack>
          </form>
        </DialogForm>
      </>
    );
  }

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Please check your email. I've sent a verification message to{" "}
            <b>{quote.item.email}</b>. Please enter your OTP.
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <TextField
              id="email_otp"
              label="OTP"
              variant="outlined"
              value={emailOTP}
              fullWidth
              onChange={(e) => setEmailOTP(e.target.value)}
              error={errors.otp !== ""}
              helperText={errors.otp}
              disabled={
                quote.item.email_confirmed ||
                isBusy ||
                quote.status === STATUS_LOADING
              }
              inputProps={{ maxLength: 6 }}
            />

            <AlertBar
              variant="outlined"
              severity="success"
              error={
                mailIsSent &&
                !isVerify &&
                `Mail sent successfully to ${quote.item.email}`
              }
              onClose={() => setMailIsSent(false)}
            />

            <AlertBar
              variant="outlined"
              error={
                isVerify &&
                "Your email hasn't been verified. Please check your inbox for our verification email and enter your OTP."
              }
              severity="info"
            />
            <Box sx={{ textAlign: "right" }}>
              {quote.item.email_confirmed ? (
                <ButtonLoading
                  disabled={!quote.item.email}
                  isLoading={isBusy || quote.status === STATUS_LOADING}
                  onClick={handleNextStep}
                >
                  Next
                </ButtonLoading>
              ) : (
                <ButtonLoading
                  disabled={!quote.item.email}
                  isLoading={isBusy || quote.status === STATUS_LOADING}
                  onClick={validateOTP}
                >
                  Validate OTP
                </ButtonLoading>
              )}
            </Box>
          </Stack>
        </form>
      </DialogForm>

      {!mailIsSent && (
        <Box sx={{ mt: 6 }}>
          <Stack gap={2}>
            <ButtonOption
              onClick={() => doResendVerificationEmail()}
              disabled={isBusy || quote.status === STATUS_LOADING}
            >
              Please Resend me a Verification Email. Be sure to check your spam
              folder also if you do not see it in your inbox.
            </ButtonOption>
          </Stack>
        </Box>
      )}
    </>
  );
}
