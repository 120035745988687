import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

import jdpApi from "../jdpApi";
import { handleAxiosError } from "../../functions/errorWithMessage";

import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCES,
  STATUS_FAILED,
  STATUS_UPDATE,
} from "../status";

import { clUser } from "../objects/clUser";
const API_ROOT = "/auth";

// Initialize
export const initializeAuth = createAsyncThunk(
  "auth/initializeAuth",
  async (_, thunkAPI) => {
    const output = {
      signed_in: false,
      token: null,
      user: clUser(),
      device_id: null,
    };
    try {
      // Set device ID
      if (!thunkAPI.getState().auth.device_id) {
        let device_id = await localStorage.getItem(
          process.env.REACT_APP_DEVICE_ID
        );

        if (!device_id) {
          output.device_id = crypto.randomUUID();
          localStorage.setItem(
            process.env.REACT_APP_DEVICE_ID,
            output.device_id
          );
        } else output.device_id = device_id;
      }

      // Check Token
      let storedToken;
      if (!thunkAPI.getState().auth.token) {
        storedToken = await localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        );

        if (!storedToken) {
          storedToken = await sessionStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          );
        }

        // Check if a valid token is avialable
        if (storedToken && storedToken !== "undefined") {
          if (process.env.NODE_ENV === "development")
            console.log("initializeAuth(): Authenticate with stored token ");

          const tokenData = jwtDecode(storedToken);
          const userInfo = clUser(tokenData.data.user);

          // Load the data from token
          output.signed_in = true;
          output.token = storedToken;
          output.user = userInfo;

          return output;
        } else {
          if (process.env.NODE_ENV === "development")
            console.log("initializeAuth(): No token available ");
          return output;
        }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Sign In
export const signinUser = createAsyncThunk(
  "auth/signinUser",
  async ({ email, password, remember }, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/signin/", {
        email: email.toLowerCase(),
        password: password,
      });

      if (remember) {
        await localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          response.data.token
        );
      } else {
        await sessionStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          response.data.token
        );
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Register
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async ({ email, password, first_name, last_name, mobile = "" }, thunkAPI) => {
    try {
      const mall = thunkAPI.getState().mall.item;

      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/register/", {
        email: email.toLowerCase(),
        password: password,
        first_name,
        last_name,
        mobile,
        company_id: mall.id,
      });
      await localStorage.setItem(
        process.env.REACT_APP_ACCESS_TOKEN,
        response.data.token
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Send Reset Password Email
export const sendResetPassword = createAsyncThunk(
  "auth/sendResetPassword",
  async ({ email }, thunkAPI) => {
    try {
      // Send request to API
      // const mall = thunkAPI.getState().mall.item;

      const response = await jdpApi.post(API_ROOT + "/password/", {
        email: email.toLowerCase(),
        // company_id: mall.id,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Reset Password
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email, otp, password }, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/password/reset", {
        email: email.toLowerCase(),
        otp,
        password,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Update Password
export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ old_password, password }, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/user/password", {
        old_password,
        password,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Sent verification Email
export const sendVerificationEmail = createAsyncThunk(
  "auth/sendVerificationEmail",
  async (_, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/verification/");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Verify Email
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async ({ email, id_check }, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/verification/email", {
        email: email.toLowerCase(),
        id_check,
      });
      if (response.data.token) {
        await localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          response.data.token
        );
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Update
export const update = createAsyncThunk(
  "auth/update",
  async ({ username, first_name, last_name, phone }, thunkAPI) => {
    try {
      // Send request to API
      const response = await jdpApi.patch(API_ROOT + "/user/", {
        username,
        first_name,
        last_name,
        phone,
      });
      if (response.data.token) {
        await localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          response.data.token
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Upload Avatar
export const uploadAvatar = createAsyncThunk(
  "auth/uploadAvatar",
  async ({ filename }, thunkAPI) => {
    try {
      // Prepare form data
      const formdata = new FormData();
      formdata.append("avatar", filename, filename.name);

      // Send request to API
      const response = await jdpApi.post(API_ROOT + "/user/avatar", formdata);

      if (response.data.token) {
        await localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          response.data.token
        );
      }

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(handleAxiosError(error));
    }
  }
);

// Structure of initial state
const initialState = {
  signed_in: false,
  token: null,
  online: navigator.onLine,
  user: clUser(),
  device_id: null,

  // Following properties need to be moved to user
  //permissions: {},

  // geo: {
  //   available: false,
  //   enabled: false,
  //   lat: 0,
  //   lon: 0,
  //   errCode: 0,
  //   errMsg: "",
  // },
  dialog_open: false,

  status: STATUS_IDLE,
  error: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // Actions
  reducers: {
    setOnlineStatus: (state, action) => {
      if (action.payload != null) state.server_online = action.payload;
      state.online = navigator.onLine;
    },
    setSignedIn: (state, action) => {
      state.signed_in = action.payload;
    },
    signOut: (state, action) => {
      // Clear all stored settings
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      sessionStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      state.signed_in = false;
      state.token = null;
    },
    resetStatus: (state, action) => {
      state.status = STATUS_IDLE;
      state.error = "";
    },
    setDialogOpen: (state, action) => {
      state.dialog_open = action.payload;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    //builder;
    builder
      // Initialize authentication via stored token
      .addCase(initializeAuth.pending, (state) => {
        state.signed_in = false;
        state.token = null;
        state.device_id = null;
        state.user = clUser();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(initializeAuth.fulfilled, (state, action) => {
        state.signed_in = action.payload.signed_in;
        state.token = action.payload.token;
        state.device_id = action.payload.device_id;
        state.user = action.payload.user;
        state.status = STATUS_SUCCES;
      })
      .addCase(initializeAuth.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Sign In User
      .addCase(signinUser.pending, (state) => {
        state.signed_in = false;
        state.token = null;
        state.user = clUser();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(signinUser.fulfilled, (state, action) => {
        state.signed_in = true;
        state.token = action.payload.token;
        const tokenData = jwtDecode(action.payload.token);
        const userInfo = clUser(tokenData.data.user);
        state.user = userInfo;
        state.status = STATUS_SUCCES;
      })
      .addCase(signinUser.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Register User
      .addCase(registerUser.pending, (state) => {
        state.signed_in = false;
        state.token = null;
        state.user = clUser();
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.signed_in = true;
        state.token = action.payload.token;
        state.token = action.payload.token;
        const tokenData = jwtDecode(action.payload.token);
        const userInfo = clUser(tokenData.data.user);
        state.user = userInfo;
        state.status = STATUS_SUCCES;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Send reset password
      .addCase(sendResetPassword.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(sendResetPassword.fulfilled, (state, action) => {
        state.status = STATUS_SUCCES;
      })
      .addCase(sendResetPassword.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Reset password
      .addCase(resetPassword.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = STATUS_SUCCES;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Update password
      .addCase(updatePassword.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.status = STATUS_SUCCES;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Send Verification Email
      .addCase(sendVerificationEmail.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.status = STATUS_SUCCES;
      })
      .addCase(sendVerificationEmail.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Verify Email
      .addCase(verifyEmail.pending, (state) => {
        state.status = STATUS_LOADING;
        state.error = "";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.token = action.payload.token;
        if (action.payload.user) {
          let readUser = clUser(action.payload.user);
          state.user = readUser;
        }
        state.status = STATUS_SUCCES;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })

      // Update
      .addCase(update.pending, (state) => {
        state.status = STATUS_UPDATE;
        state.error = "";
      })
      .addCase(update.fulfilled, (state, action) => {
        if (action.payload.token) {
          state.token = action.payload.token;
          const tokenData = jwtDecode(action.payload.token);
          const userInfo = clUser(tokenData.data.user);
          state.user = userInfo;
        }
        state.status = STATUS_SUCCES;
      })
      .addCase(update.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      })
      // Upload Avatar
      .addCase(uploadAvatar.pending, (state) => {
        state.status = STATUS_UPDATE;
        state.error = "";
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        if (action.payload.token) {
          state.token = action.payload.token;
          const tokenData = jwtDecode(action.payload.token);
          const userInfo = clUser(tokenData.data.user);
          state.user = userInfo;
        }
        state.status = STATUS_SUCCES;
      })
      .addCase(uploadAvatar.rejected, (state, action) => {
        state.status = STATUS_FAILED;
        state.error = action.payload;
      });
  },
});

export const {
  setOnlineStatus,
  setSignedIn,
  signOut,
  resetStatus,
  setDialogOpen,
} = authSlice.actions;

export default authSlice.reducer;
