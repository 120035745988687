import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import "../../css/form.css";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";
import AlertBar from "../AlertBar";

// State
import { STATUS_LOADING } from "../../app/status";
import {
  validateFormErrors,
  animateForm,
  validateEmail,
} from "../../functions/inputValidation";
import {
  updateProperty,
  nextStep,
  resetStatus,
  verifyOTP,
} from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserContactVerify() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [values, setValues] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [errors, setErrors] = useState({
    otp: "",
  });

  const handleChange = (prop) => (e) => {
    const newValue = e.target.value.length > 1 ? "" : e.target.value;
    setValues({
      ...values,
      [prop]: newValue,
    });
    setErrors({ ...errors, otp: "" });
    if (newValue != "") {
      let el = null;
      if (prop === "otp1") {
        el = document.getElementById("otp2");
      } else if (prop === "otp2") {
        el = document.getElementById("otp3");
      } else if (prop === "otp3") {
        el = document.getElementById("otp4");
      } else el = document.getElementById("otp1");

      el.select();
      el.focus();
    }
  };

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      otp: "",
    };

    if (
      values.otp1 === "" ||
      values.otp2 === "" ||
      values.otp3 === "" ||
      values.otp4 === ""
    )
      tmpErr.otp = "Please enter your OTP.";

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      const otp = values.otp1 + values.otp2 + values.otp3 + values.otp4;
      dispatch(verifyOTP({ otp: otp }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          dispatch(nextStep());
          setIsBusy(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          //Something went wrong
          animateForm("editForm");
        })
        .finally(() => {
          setIsBusy(false);
        });
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  return (
    <>
      <DialogIntro
        messages={[
          <>
            Please verify your contact details. I've sent a code to your mobile:{" "}
            <b>{quote.item.phone}</b>
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <Box sx={{ textAlign: "center", fontWeight: 600 }}>
              Enter the 4-digit code:
            </Box>
            <Stack direction="row" gap={2}>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Input
                id="otp1"
                value={values.otp1}
                onChange={handleChange("otp1")}
                type="number"
                error={errors.otp !== ""}
                className="number-input"
                disabled={isBusy}
                sx={{ maxWidth: "80px" }}
              />
              <Input
                id="otp2"
                value={values.otp2}
                onChange={handleChange("otp2")}
                type="number"
                error={errors.otp !== ""}
                className="number-input"
                //disabled={values.otp1 === "" || isBusy}
                sx={{ maxWidth: "80px" }}
              />
              <Input
                id="otp3"
                value={values.otp3}
                onChange={handleChange("otp3")}
                type="number"
                error={errors.otp !== ""}
                className="number-input"
                //disabled={values.otp2 === "" || isBusy}
                sx={{ maxWidth: "80px" }}
              />
              <Input
                id="otp4"
                value={values.otp4}
                onChange={handleChange("otp4")}
                type="number"
                error={errors.otp !== ""}
                className="number-input"
                //disabled={values.otp3 === "" || isBusy}
                sx={{ maxWidth: "80px" }}
              />
              <Box sx={{ flexGrow: 1 }}></Box>
            </Stack>
            <AlertBar error={errors.otp} />
            {/* <AlertBar
              error={quote.error}
              onClose={() => dispatch(resetStatus())}
            /> */}
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px", mt: 2 }}
                disabled={
                  (!values.otp1 &&
                    !values.otp2 &&
                    !values.otp3 &&
                    !values.otp4) ||
                  isBusy ||
                  quote.status === STATUS_LOADING
                }
                onClick={handleNextStep}
              >
                Verify
              </Button>
            </Box>
            {quote.item.email_confirmed && (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  sx={{ minWidth: "150px" }}
                  disabled={isBusy || quote.status === STATUS_LOADING}
                  onClick={() => dispatch(nextStep())}
                >
                  Next
                </Button>
              </Box>
            )}
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
