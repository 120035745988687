import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

// Components
import DialogIntro from "./DialogIntro";
import DialogForm from "./DialogForm";

// State
import { doc_types } from "../../app/lists/doc_types";
import {
  isNGId,
  validateFormErrors,
  animateForm,
} from "../../functions/inputValidation";
import { updateProperty, nextStep } from "../../app/features/quoteSlice";

//******************************************************************************
export default function StepUserIdNr() {
  const dispatch = useDispatch();
  const quote = useSelector((state) => state.quote);
  const [isBusy, setIsBusy] = useState(false);

  const [errors, setErrors] = useState({
    id_number: "",
  });

  const handleNextStep = () => {
    setIsBusy(true);
    const tmpErr = {
      id_number: "",
    };

    if (quote.item.id_number === "")
      tmpErr.id_number = "Please enter your ID Number.";
    else if (quote.item.id_document === "National ID") {
      if (!isNGId(quote.item.id_number))
        tmpErr.id_number = "Please enter a valid NIN.";
    } else if (quote.item.id_document === "Driver's License") {
      if (!isNGId(quote.item.id_number))
        tmpErr.id_number = "Please enter a valid NDL.";
    }

    setErrors(tmpErr);

    if (!validateFormErrors(tmpErr)) {
      dispatch(nextStep());
      setIsBusy(false);
    } else {
      animateForm("editForm");
      setIsBusy(false);
    }
  };

  const id_label = doc_types
    .filter((item) => item.value === quote.item.id_document)
    .map((item) => item.number);

  return (
    <>
      <DialogIntro
        messages={[
          <>
            What is the identification number on your{" "}
            <b>{quote.item.id_document}</b>?
          </>,
        ]}
      />

      <DialogForm>
        <form id={"editForm"}>
          <Stack gap={2}>
            <TextField
              id="id_number"
              label={id_label}
              variant="outlined"
              value={quote.item.id_number}
              fullWidth
              onChange={(e) =>
                dispatch(
                  updateProperty({
                    prop: "id_number",
                    value: e.target.value,
                  })
                )
              }
              error={errors.id_number !== ""}
              helperText={errors.id_number}
              required
              disabled={isBusy}
              inputProps={{ maxLength: 50 }}
            />

            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                sx={{ minWidth: "150px" }}
                disabled={!quote.item.id_number || isBusy}
                onClick={handleNextStep}
              >
                Next
              </Button>
            </Box>
          </Stack>
        </form>
      </DialogForm>
    </>
  );
}
